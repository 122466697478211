//// ----------------------------------------------------------------------
///
/// Tabs item
///
/// @group containers
/// @module sass/components/containers/tabs

//// ----------------------------------------------------------------------

// Variables for tabs
$es-tabs-border-color: $es-border-light-color;

$es-tabs-background-inactive-color: es-color('clario-mono', 'gray-2');
$es-tabs-text-inactive-color: es-color('clario-mono', 'gray-11');

$es-tabs-background-active-color: es-color('clario-mono', 'white');
$es-tabs-text-active-color: $es-font-color;

$es-tabs-background-hover-color: es-color('clario-mono', 'gray-5');
$es-tabs-text-hover-color: $es-font-color;

.es-tabs {
	display: flex;
	flex-direction: row;
	list-style: none;
	margin: 0;
	// margin-bottom: es-em-calc(-1); 
	position: relative; // IE fix: forces tab to overlap panels
	padding-left: 0;
	width: 100%;
}

.es-tab {
	background: none;
	// border: 1px solid $es-tabs-border-color;
	// border-bottom: none;
	// display: none;
	justify-content: stretch;
	position: relative;
	background-color: $es-tabs-background-inactive-color;

	&::after {
		transition: all .3s cubic-bezier(1, 0, 0, 1);
		will-change: transform, box-shadow, opacity;
		position: absolute;
		content: '';
		height: 3px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		background: es-color('clario','purple-d');
		opacity: 0;
		transform: scale(0, 1);
	}

	&:not(:last-child) {
		margin: es-to-rem($es-space-gutter-right-small);
	}

	& > * {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color:$es-tabs-text-inactive-color;

		&:hover {
			background: $es-tabs-background-hover-color;
			color: $es-tabs-text-hover-color;
			text-decoration: none;;
		}
	}

	& > a {
		padding: es-to-rem($es-space-inset-default);
	}

	&.-es-more {
		display: flex;
		flex-direction: column;

		& > .es-dropdown {
			margin-right: 0;

			display: flex;
			flex-direction: column;
			flex-grow: 1;
			
			& > button {
				display: flex;
				color: $es-tabs-text-inactive-color;
				flex-grow: 1;
				padding: es-to-rem($es-space-inset-default);
			}
		}

		// correct color of button on hover
		&:hover {
			& > .es-dropdown {
				& > button {
					color: $es-tabs-text-hover-color;
				}
			}
		}
		
		// show active list element below more navigation in bold
		& ul li.-es-active {    
			font-weight: bold;
		}
	}

	&.-es-active {
		background: es-color('clario-mono','gray-3');
		display: flex;

		// deactivate links when active
		& > a {
			pointer-events: none;
		}

		& > * {
			color: $es-tabs-text-active-color;
			
			&:hover {
				background: none;
			}
		}    

		&::after {
			opacity: 1;
			transform: scale(1, 1);
		}		
	}  
}

// hide inactive tabs and more tab when tablet and above
@include es-viewport-range(mobile-large-up) {
	.es-tab {
		display: flex;

		&.-es-more {
			display: none;
		}
	}
}

// modifier -es-light
// .es-tabs.-es-light {
// 	@mixin light-tab-border($color) {
// 		background: $color;
// 		bottom: 0;
// 		content: '';
// 		height: 2px;
// 		left: 0;
// 		position: absolute;
// 		width: 100%;
// 	}

// 	margin: 0;

// 	.es-tab {
// 		background: none;
// 		border-color: transparent;
// 		flex-grow: 1;
// 		justify-content: center;
// 		margin: 0;
		
// 		// put "more" dots at right, deactivate hover for light tabs
// 		&.-es-more > .es-dropdown {
// 			align-items: flex-end;

// 			&:hover {
// 				background: none;
// 			}

// 			& > button {
// 				padding: 0;
// 			}
// 		}
// 		& > a {
// 			background: none;
// 			text-align: center;
// 			width: 100%;
// 			position: relative;

// 			&:hover:after {
// 				@include light-tab-border($es-tabs-text-inactive-color);
// 			}
// 		}
// 		&.-es-active a:after {
// 			@include light-tab-border($es-tabs-text-inactive-color);
// 		}
// 	}
// }
