//// ----------------------------------------------------------------------
///
/// Quicklinks component
///
/// @group navigation
/// @module sass/components/navigation/quicklinks
///
//// ----------------------------------------------------------------------

.es-quicklinks {
	$es-quicklinks-container-size: 90px;
	$es-quicklinks-container-size-mobile: 50px;

	$es-quicklinks-icon-font-size: 20px;
	$es-quicklinks-icon-padding: $es-space-medium 0;
	$es-quicklinks-icon-padding-mobile: $es-space-medium 0;

	$es-quicklinks-background-color: es-color('clario','header-bg-purple');
	$es-quicklinks-background-image: '#{$es-path-to-assets}images/nav-bg.svg';

	$es-quicklinks-item-inset: $es-space-inset-default;
	$es-quicklinks-item-inset-mobile: $es-space-inset-small;
	$es-quicklinks-item-background-color: es-color('mono', 'white');
	$es-quicklinks-item-color-hover: es-color('clario-purple', 'purple-8');
	$es-quicklinks-item-color: es-color('clario-purple', 'purple-9');

	$es-quicklinks-anchor-inset: $es-space-default $es-space-small;
	$es-quicklinks-anchor-inset-mobile: $es-space-small $es-space-xsmall;
	$es-quicklinks-anchor-border-radius: 4px;
	
	$es-quicklinks-text-color-hover: es-color('mono', 'oslo-grey');
	$es-quicklinks-text-color: es-color('mono', 'abbey');
	$es-quicklinks-text-font-size: $es-small-font-size;
	$es-quicklinks-text-max-num-lines: 2;

	$es-quicklinks-service-type-color-hover: es-color('clario-purple', 'purple-8'); // service type now visually matches default
	$es-quicklinks-service-type-color: es-color('clario-purple', 'purple-9');

	$es-quicklinks-dropdown-chevron-size: 8px;
	$es-quicklinks-dropdown-chevron-bottom-position: 4px;
	$es-quicklinks-dropdown-chevron-bottom-position-mobile: 2px;

	$es-quicklinks-placeholder-icon-font-size-mobile: 36px;
	$es-quicklinks-placeholder-icon-font-size: 70px;
	$es-quicklinks-placeholder-text-color: es-color('mono', 'alto');

	&.--with-bg {
	background: url($es-quicklinks-background-image);
	background-repeat: no-repeat;
	background-position: 0px -1*$es-page-header-height;
	background-color: $es-quicklinks-background-color;
	position: fixed;
	width: 100%;
	padding: $es-space-default;
	padding-block-end: $es-space-inset-large;
	z-index:$es-global-header-z-index - 1;
	top: 50px;
	}


	ul {
		@extend %es-reset-ul;
		// remove outer spacing
		margin: es-to-rem($es-quicklinks-item-inset-mobile * -1);
	}

	

	.es-quicklinks-item {
		display: inline-block;
		padding: es-to-rem($es-quicklinks-item-inset-mobile);
		text-align: center;
		vertical-align: top;

		> .es-placeholder,
		> .es-dropdown > a,
		> a {
			// border color not explicitly set, it's automatically
			// inherited from the element's color by default.
			background-color: $es-quicklinks-item-background-color;
			border: none;
			border-radius: $es-quicklinks-anchor-border-radius;
			display: block;
			height: es-to-rem($es-quicklinks-container-size-mobile);
			width: es-to-rem($es-quicklinks-container-size-mobile);
			text-decoration: none;

			span + span {
				display:none;
				color: $es-quicklinks-text-color;
				font-family: $es-font-secondary;
				font-size: es-to-rem($es-quicklinks-text-font-size);
				line-height: 1.15em;
				overflow: hidden;
				position: relative;
				text-overflow: ellipsis;
				text-decoration: none;
				max-height: es-to-rem($es-quicklinks-text-font-size * $es-quicklinks-text-max-num-lines + $es-space-small);
				text-align: center;
				height: 100%;
			}
	
			.es-icon {
				display: block;
			}

		}

		// quick link specific
		> .es-dropdown > a,
		> a {
			color: $es-quicklinks-item-color;
			padding: es-to-rem($es-quicklinks-anchor-inset-mobile);
			
			&:hover {
				color: $es-quicklinks-item-color-hover;
				// change hover color of text span below icon as well
				span + span {
					color: $es-quicklinks-text-color-hover;
				}
			}
			&:focus {
				opacity: 1;
			}

			.es-icon {
				padding: es-to-rem($es-quicklinks-icon-padding-mobile);
				font-size: es-to-rem($es-quicklinks-icon-font-size);
			}
		}		

		// placeholder specific
		> .es-placeholder {
			color: $es-quicklinks-placeholder-text-color;
			.es-icon {
				font-size: es-to-rem($es-quicklinks-placeholder-icon-font-size-mobile);
			}
		}


		> .es-dropdown > a {
			&:after {
				@include es-control-icon(caret-down, $es-quicklinks-dropdown-chevron-size);
				bottom: es-to-rem($es-quicklinks-dropdown-chevron-bottom-position-mobile);
				color: $es-quicklinks-text-color;
				left: calc(50% - #{calc($es-quicklinks-dropdown-chevron-size / 2)});
				line-height: 1em;
				position: absolute;
			}

			&:hover {
				&:after {
					color: $es-quicklinks-text-color-hover;
				}
			}

		} 

		// additional coloring for quicklinks of type "service"
		&.-es-type-service {
			> .es-dropdown > a,
			> a {
				color: $es-quicklinks-service-type-color;
				&:hover {
					color: $es-quicklinks-service-type-color-hover;
				}
			}
		}
		
		&.-es-clip {
			clip-path: polygon(0 0, 28px 0, 36px 14px, 30px 24px, 36px 34px, 30px 45px, 37px 56px, 30px 68px, 37px 78px, 24px 100px, 100px 100px, 0 100px);
		}
				
	}

	@include es-viewport-range(tablet-up) {
		
		ul {
			// remove outer spacing
			margin: es-to-rem($es-quicklinks-item-inset * -1);
		}

		.es-quicklinks-item {
			padding: es-to-rem($es-quicklinks-item-inset);

			> .es-placeholder,
			> .es-dropdown > a,
			> a {
				border: none;
				border-radius: $es-quicklinks-anchor-border-radius;
				height: es-to-rem($es-quicklinks-container-size);
				width: es-to-rem($es-quicklinks-container-size);

				span + span {
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 600;
				}
			}

			// anchor/dropdown specific
			> .es-dropdown > a,
			> a {
				padding: es-to-rem($es-quicklinks-anchor-inset);
				.es-icon {
					padding: es-to-rem($es-quicklinks-icon-padding);
				}
			}
			
			// placeholder specific
			> .es-placeholder {
				.es-icon {
					font-size: es-to-rem($es-quicklinks-placeholder-icon-font-size);
				}
			}

			> .es-dropdown > a {
				&:after {
					bottom: es-to-rem($es-quicklinks-dropdown-chevron-bottom-position);
				}
			}

			&.-es-clip {
				clip-path: polygon(0 0, 52px 0, 60px 14px, 54px 24px, 60px 34px, 54px 45px, 61px 56px, 53px 68px, 60px 78px, 47px 100px, 100px 100px, 0 100px);
			}
	
		}
	}

}
