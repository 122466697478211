//// ----------------------------------------------------------------------
///
/// Layout "Flex List"
///
/// @group foundation
/// @module scss/foundation/layouts
///
//// ----------------------------------------------------------------------

$es-layout-flex-list-embedded-container-selector: '.es-panel, .es-card, .es-content-box, .es-text-group, .sg-box';


/// Layout Grid
//// ----------------------------------------------------------------------
.es-layout-flex-list {  
  // reset to ensure it's properly working, when used on an ul
  @extend %es-reset-ul;

  @extend %es-flex-container-dynamic;
  flex-basis: 0;
  flex-wrap: wrap;
  min-height: min-content;

  &.-es-vertical {
    flex-direction: column;
  }  

  & > .es-flex-item {
    @extend %es-flex-container-static;
    width: 100%;

    @include es-layout-create-default-width-modifiers(12, "&");
  }

  @include es-viewport-range(mobile-large-up) {
    &.-es-small-5-column > .es-flex-item { width: 20%; flex: none; }
    &.-es-small-4-column > .es-flex-item { width: 25%; flex: none; }
    &.-es-small-3-column > .es-flex-item { width: 33.3333%; flex: none; }
    &.-es-small-2-column > .es-flex-item { width: 50%; flex: none; }
  }

  @include es-viewport-range(tablet-up) {
    &.-es-medium-5-column > .es-flex-item { width: 20%; flex: none; }
    &.-es-medium-4-column > .es-flex-item { width: 25%; flex: none; }
    &.-es-medium-3-column > .es-flex-item { width: 33.3333%; flex: none; }
    &.-es-medium-2-column > .es-flex-item { width: 50%; flex: none; }

    // default: 2, 3, 4 or 5 columns
    &.-es-5-column > .es-flex-item { width: 20%; flex: none; }
    &.-es-4-column > .es-flex-item { width: 25%; flex: none; }
    &.-es-3-column > .es-flex-item { width: 33.3333%; flex: none; }
    &.-es-2-column > .es-flex-item { width: 50%; flex: none; }
  }

  @include es-viewport-range(notebook-up) {
    &.-es-large-5-column > .es-flex-item { width: 20%; flex: none; }
    &.-es-large-4-column > .es-flex-item { width: 25%; flex: none; }
    &.-es-large-3-column > .es-flex-item { width: 33.3333%; flex: none; }
    &.-es-large-2-column > .es-flex-item { width: 50%; flex: none; }
  }

  @include es-viewport-range(desktop-up) {
    &.-es-xlarge-5-column > .es-flex-item { width: 20%; flex: none; }
    &.-es-xlarge-4-column > .es-flex-item { width: 25%; flex: none; }
    &.-es-xlarge-3-column > .es-flex-item { width: 33.3333%; flex: none; }
    &.-es-xlarge-2-column > .es-flex-item { width: 50%; flex: none; }
  }


  // adjust bheaviour for embedded/cascaded containers
  #{$es-layout-flex-list-embedded-container-selector} {
    @extend %es-flex-container-dynamic;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}