//// ----------------------------------------------------------------------
///
/// Actionbar
///
/// @group components
/// @module sass/components/basics/actionbar
/// @require mixin es-to-rem
/// @author Daniel Lenhart, Axel Pfeuffer
///
//// ----------------------------------------------------------------------

.es-actionbar {

  // configuration
  $es-actionbar-icon-size: $es-icon-size-large;
  $es-actionbar-default-color: es-color('clario-mono', 'gray-3');
  
  @include es-clearfix;

  white-space: nowrap;
  display: inline-block;
  
  // adjust icon size (have it a bigger)
  .es-icon {
    font-size: es-to-rem($es-actionbar-icon-size);
  }

  // style embedded buttons and links
  a,
  button.es-icon {
    padding: 0;

    @extend %es-default-button-reset;
    color: $es-icon-color;

    &:hover{
      color: es-color('clario-mono','gray-12');
    } 
    &:disabled {
      color: $es-disabled-color;
    }
  }

  .es-separator {
    background-color: $es-control-separator-color;
    height: es-to-rem($es-control-height);
    padding: es-to-rem(0 $es-space-small);
    width: es-to-rem($es-space-small*2 + 1px); // twice spacing + 1px intended width
    display: inline-block;
    vertical-align: bottom;
    background-clip: content-box;
  }

  // assure space between close button and adjacent element
  & > *:not(:last-child) {
    // harmonize spacing manually: visually remove white space (subtract ca. 2px)
    margin: 0 calc(#{es-to-rem($es-space-default)} - .125em) 0 0;
  }
}
