//// ----------------------------------------------------------------------
///
/// Panel item, container for separation of content from the background
///
/// @group containers
/// @module sass/components/containers/panel

//// ----------------------------------------------------------------------

$es-panel-borderless-margin: 0 $es-space-default*-1 $es-space-default*-1 $es-space-default*-1;

.es-panel {
  $es-panel-text-default-color: $es-font-color;

  // Background color and border
  @extend %es-container-background-with-border;
  // Inset spacing
  @extend %es-container-inset-modifiers;
  // set default color
  color: $es-panel-text-default-color;
  width: 100%;
}

.es-panel-group {
  & > .es-panel {
    display: none;

    &.-es-active {
      display: block;
    }
  }

  &.-es-borderless {
    // remove unnecessary spacing
    margin: es-to-rem($es-panel-borderless-margin);

    // no border for panel
    .es-panel {
      border: none;
    }
  }
}
