//// ----------------------------------------------------------------------
///
/// miscellaneous util modules
///
/// @group modules
/// @module sass/modules/core/util
///
//// ----------------------------------------------------------------------

//// ----------------------------------------------------------------------
///
/// Converts a given pixel value based on a context to a specified baseunit.
///
/// @access public
/// @param px | number or list $pixels A pixel value or list of pixel values to calculate
/// @param px | number [$context] A pixel value to equal 1 baseunit
/// @param unit [$baseUnit] A base unit: 1em or 1rem
/// @outputs The calculated baseunit value.

@function es-percentage($value, $base) {
    $result: calc(($value * 100%) / $base);  
//    @debug "Percentage = #{$result}";
    @return $result;
}



//// ----------------------------------------------------------------------
///
/// Overflow handling mixins
///

@mixin es-overflow-wrap {
    // official CSS3 compliant spec
    overflow-wrap: break-word;

    // @TODO: WORKAROUND for IE compatibility reasons, to be removed when IE fixes this
    word-wrap: break-word;
}


//// ----------------------------------------------------------------------
///
/// Overflow handling: text with ellipsis
/// Please note that overflow: hidden is required to be set in order to 
/// get this to work.
///
@mixin es-overflow-text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

//// ----------------------------------------------------------------------
///
/// Function for conversion to prevent unicode bug
///
/// @access public
///

@function unicode($value) { 
    @return unquote('"' + str-insert($value, '\\', 1) + '"'); 
} 