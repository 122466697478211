//// ----------------------------------------------------------------------
///
/// mixins, extensions, functions or placeholder for resetting standard 
/// markup elements
///
/// @group modules
/// @module sass/modules/core/reset
//// ----------------------------------------------------------------------

// reset unordered list
%es-reset-ol,
%es-reset-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

// Needed to get rid of the rounded input corners and boxshadow on safari
%es-control-ios-css-reset {
    border-radius: 0;
    box-shadow: none;
}