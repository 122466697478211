//// ----------------------------------------------------------------------
///
/// Toolbar container settings
///
/// @group containers
/// @module sass/components/containers/tabs
/// @author Daniel Lenhart, Axel Pfeuffer
///
//// ----------------------------------------------------------------------

// toolbar, currently used for buttons and links
.es-toolbar {
  @include es-clearfix;
  width: 100%;

  // toolbar item & list
  ul {
    @extend %es-reset-ul;
  }

  .es-toolbar-item {
    display: inline-block;
    padding: es-to-rem($es-space-small);
    width: 100%;
    & > * {
      width: 100%;
    }
  }

  // Separator to visually indicate a divide between elements in the toolbar
  // Horizontal in mobile, vertical on tablets and up
  .es-separator {
    background-color: $es-control-separator-color;
    height: 1px;
    display: block;
  }

  // Fill padding for control height
  .es-checkbox,
  .es-radio {
    @extend %es-checkbox-radio-padding;
  }

  .es-switch {
    @extend %es-control-switch-padding-fill;
  }
}


@include es-viewport-range(mobile-large-up){
  .es-toolbar {
    .es-separator {
      height: es-to-rem($es-control-height);
      width: 1px;
    }

    .es-right,
    .-es-right,
    ul.-es-right {
      float: right;
    }

    .es-toolbar-item {
      float: left;
      width: auto;
    }

    // visual harmonization for specific elements
    .es-label,
    .es-toolbar-item > a:not(.es-button):not(.es-tag) {
      // fill up space above and below label
      padding: es-to-rem(($es-space-small + 1px) 0);
      display: inline-block;
    }
  }
}





