//// ----------------------------------------------------------------------
///
/// Breadcrumbs, as navigation item
///
/// @group navigation
/// @module sass/components/navigation/breadcrumbs

//// ----------------------------------------------------------------------

.es-breadcrumbs {
  // small correction on right spacing, due to white space between <li>
  $breadcrumbs-left-right-margin: 0 $es-space-small 0 $es-space-default;

  display: inline-block;

  a {
    color: es-color('clario-mono','gray-11');
    font-weight: normal;
    &:hover{
      color: $es-anchor-color;
      text-decoration: underline;
    }
  }
  li{
    color: es-color('clario-mono','gray-11');
  }

  & > nav > ul > li:last-child{
    color: es-color('clario','purple-d');
  }

  & > nav > ul {
    @extend %es-reset-ul;
  }
  & > nav > ul > li:not(:last-child) {
    &:after {
      @include es-control-icon(chevron-right, 10px);
      margin: es-to-rem($breadcrumbs-left-right-margin);
      vertical-align: top;
      color: es-color('clario-mono','gray-8');
    }
  }
  &> nav > ul > li:not(.es-more) {
    display: inline-block;
  }
  .es-icon {
    margin: es-to-rem($es-space-gutter-left-xsmall);
  }
}

@include es-viewport-range(mobile-large-up) {
  .es-more {
    display: none;
  }
}

@include es-viewport-range(mobile-only) {
  .es-more {
    display: inline-block;
  }
}
