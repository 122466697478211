//// ----------------------------------------------------------------------
///
/// App template
///
/// @group templates
/// @module sass/templates/App
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

.es-app {
  @extend %es-page;

  .es-app-body {
    @include es-clearfix;
    @extend %es-container-inset-modifiers;

    &.-es-fixed-width {
      @extend %-es-page-body-fixed-width;
      margin: 0 auto;
    }
  }

  .es-app-footer {
    @extend %es-page-footer;
    .es-page-footer-layout {
      background-color: $es-background-color;
      border-top: $es-page-footer-border;
      li+li:before {
        color: $es-anchor-color;
      }
    }
  }
}