//// ----------------------------------------------------------------------
///
/// Overlay for form controls
///
/// @group forms
/// @module sass/components/form-controls/form-control-overlay
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

.es-form-control-overlay {
  // overlay configuration
  $overlay-inset-right: $es-icon-size-default + $es-space-default + $es-space-small;
  $overlay-inset: $es-space-small $overlay-inset-right $es-space-small $es-space-medium;
  $overlay-icon-inset: $es-space-medium $es-space-medium $es-space-medium $es-space-small;

  // overlay state colors
  $overlay-state-success-icon-color: $es-success-color;
  $overlay-state-info-icon-color: $es-info-color;
  $overlay-state-warning-icon-color: $es-warning-color;
  $overlay-state-error-icon-color: $es-danger-color;

  position: relative;

  & > *:first-child {
    padding-right: es-to-rem($overlay-inset-right);
  }

  // generic overlay handling and for badge
  & > .-es-overlay {
    position: absolute;
    right: 0;
    top: 0;
  }
  & > span:not(.es-badge).-es-overlay {
    padding: es-to-rem($overlay-icon-inset);
  }
  & > span.es-badge.-es-overlay {
    // adjust position for badge used as overlay
    right: es-to-rem($es-space-xsmall);
    top: es-to-rem($es-space-xsmall);
  }

  // colors for different states of overlay
  &.-es-success > .-es-overlay {
    color: $overlay-state-success-icon-color;
  }
  &.-es-info > .-es-overlay {
    color: $overlay-state-info-icon-color;
  }
  &.-es-warning > .-es-overlay {
    color: $overlay-state-warning-icon-color;
  }
  &.-es-error > .-es-overlay {
    color: $overlay-state-error-icon-color;
  }

}
