//// ----------------------------------------------------------------------
///
/// Mixins/Functions for Layouts
///
/// @group foundation
/// @module scss/modules/foundation/layouts
///
//// ----------------------------------------------------------------------


/// Mixin to create even layouts
//// ----------------------------------------------------------------------
@mixin es-layout-create-even-column-layout($layout-name, $column-class, $num-columns) {
    
    // pre-calculate column width
    $column-width: calc(100% / $num-columns);
     
    &.#{$layout-name} {
        .#{$column-class} {
          width: #{$column-width};
        }
    }
       
}

 
  
/// Mixin to create width modifiers
//// ----------------------------------------------------------------------
@mixin es-layout-create-width-modifiers($num, $prefix-name, $parent: "") {
    @for $idx from 1 through $num {
        #{$parent}.#{$prefix-name}#{$idx} {
            width: calc(100% / $num) * $idx;
        }
    }
}

@mixin es-layout-create-default-width-modifiers($num, $parent: "") {
    // small: width modifiers take effect when mobile and up (= mobile-large-up)
    @include es-viewport-range(mobile-large-up) {
        @include es-layout-create-width-modifiers($num, "-es-small-", $parent);      
    }

    // medium: width modifiers take effect when tablet and up (= tablet-up)
    @include es-viewport-range(tablet-up) {
        @include es-layout-create-width-modifiers($num, "-es-medium-", $parent);      

        // default width
        @include es-layout-create-width-modifiers($num, "-es-width-", $parent);      
    }
  
    // large: width modifiers take effect when notebook and above 
    @include es-viewport-range(notebook-up) {
        @include es-layout-create-width-modifiers($num, "-es-large-", $parent);      
    }

    // xlarge: width modifiers take effect when desktop and above 
    @include es-viewport-range(desktop-up) {
        @include es-layout-create-width-modifiers($num, "-es-xlarge-", $parent);      
    }
}

  
/// Mixin to create height modifiers
//// ----------------------------------------------------------------------
@mixin es-layout-create-height-modifiers($num, $prefix-name, $parent: "") {
    @for $idx from 1 through $num {
        #{$parent}.#{$prefix-name}#{$idx} {
            height: 100% / $num * $idx;
        }
    }
}


  

/// Predefined layout extensions
//// ----------------------------------------------------------------------
%es-layout {
    @include es-clearfix;
    // SHOULD NOT BE NECESSARY!
    box-sizing: border-box;
}

%es-layout-row {
    width: 100%;
}

%es-layout-float-column {
    float: left;
    width: 100%;
}


