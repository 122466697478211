//// ----------------------------------------------------------------------
///
/// mixins, extensions and functions for transistions
/// markup elements
///
/// @group modules
/// @module sass/modules/core/transistions
//// ----------------------------------------------------------------------

@mixin es-transition-collapse-vertical($height: 0) {
  opacity: 0;
  max-height: $height;
  overflow: hidden;
}

@mixin es-transition-expand-vertical($height) {
  opacity: 1;
  max-height: $height;
  overflow: inherit;
}

@mixin es-transition-collapse-horizontal($width: 0) {
  max-width: $width;
}

@mixin es-transition-expand-horizontal($width) {
  max-width: $width;
}