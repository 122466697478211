//// ----------------------------------------------------------------------
///
/// Dropdown component
///
/// @group component
/// @module sass/component/basics/dropdown
///
//// ----------------------------------------------------------------------

.es-dropdown {
  @extend %es-dropdown;
  // reset button style when a blank button is directly embedded
  // but ignore other buttons that have a class applied
  & > button:not([class]) {
      @extend %es-default-button-reset;
  }
  
  // style for standard drop down button
  & > .es-dropdown-button {
    @extend %es-default-button-reset;
    @include es-dropdown-button;
  }

  // style for drop down button with icon and text
  & > .es-dropdown-icon {
    @extend %es-default-button-reset;
    @include es-dropdown-icon;
  }
  
  // disabled state
  & > button:disabled:hover {
    @extend %es-control-disabled;
  }

  & a,
  & a:hover {
    text-decoration: none;
  }

}

