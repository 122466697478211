/// ----------------------------------------------------------------------
///
/// typography styles
///
/// @group foundation
/// @module scss/foundation/typography
///
//// ----------------------------------------------------------------------


%es-typo-settings-heading {
  font-family: $es-font-headings;
  font-weight: $es-font-weight-headings;
  // text-transform: uppercase;
}

%es-typo-settings-h1 {
  @extend %es-typo-settings-heading;
  font-size: es-to-rem($es-h1-font-size);
  line-height: es-to-rem($es-h1-line-height);
  margin: es-to-rem($es-space-stack-default);
  font-weight: $es-font-weight-h1;
}

%es-typo-settings-h2 {
  @extend %es-typo-settings-heading;
  font-size: es-to-rem($es-h2-font-size);
  line-height: es-to-rem($es-h2-line-height);
  margin: es-to-rem($es-space-stack-default);
  font-weight: $es-font-weight-h2;
}

%es-typo-settings-h3 {
  font-size: es-to-rem($es-h3-font-size);
  line-height: es-to-rem($es-h3-line-height);
  margin: es-to-rem($es-space-stack-small);
  font-weight: $es-font-weight-h3;
}

%es-typo-settings-h4 {
  @extend %es-typo-settings-heading;
  font-size: es-to-rem($es-h4-font-size);
  line-height: es-to-rem($es-h4-line-height);
  margin: es-to-rem($es-space-stack-small);
  font-weight: $es-font-weight-h4;
}

h1, .h1 {
  @extend %es-typo-settings-h1;
}

h2, .h2 {
  @extend %es-typo-settings-h2;
}

h3, .h3 {
  @extend %es-typo-settings-h3;
}

h4, .h4 {
  @extend %es-typo-settings-h4;
}

small {
  font-size: es-to-rem($es-small-font-size);
}

p {
  margin: $es-space-stack-large;
}

a {
  color: $es-anchor-color;
  text-decoration: none;
  text-underline-offset: .4rem;

  &:hover {
    color: $es-anchor-hover-color;
    text-decoration: underline;
    & span.es-icon {
      text-decoration: none;
    }
  }
  
  &:focus {
    color: $es-anchor-focus-color;
    opacity: 0.9;
  }
  &.-es-contrast {
    color: $es-font-bright-color;

    &:hover {
      color: $es-anchor-contrast-hover-color;
      span:not(.es-icon) {
        text-decoration: underline;
      }
    }
  }
}
p > a:not(.es-button) {
  text-decoration: underline;
}

ul {
  margin: 0;
  padding: es-to-rem($es-space-gutter-left-large);
  list-style-type: square;
}

.es-text-ellipsis,
.-es-text-ellipsis {
  @include es-overflow-text-ellipsis;
}