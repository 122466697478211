//// ----------------------------------------------------------------------
///
/// Content box
///
/// @group partials
/// @module sass/boxes/content-box
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

.es-content-box {
  @extend %es-content-box-inset-modifiers;

  &.-es-borderless {
    margin: 0 es-to-rem($es-space-small * -1);
  }
}