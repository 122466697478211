//// ----------------------------------------------------------------------
///
/// Form
///
/// @group forms
/// @module sass/components/forms
///
/// @require mixin es-em-calc
/// @require mixin es-viewport-range
//// ----------------------------------------------------------------------

// Form Aligned layouts

// tablet upwards
@include es-viewport-range(tablet-up) {
  // label and control aligned
  .es-form-field.-es-aligned,
  .es-form.-es-aligned .es-form-field {

    // first child should be label => always 40%
    #{$es-form-field-label-selector} {
      width: 40%;
    }

    // second/last child should be control => always 60%
    #{$es-form-field-control-selector} {
      width: 60%;
      float: right;
    }

    .es-group,
    .es-form-control-overlay {
      #{$es-form-field-control-selector} {
        float: left;
      }
    }

    // form field ratio: label 50%, control 50%
    &.-es-field-ratio-50-50 {
      #{$es-form-field-label-selector} {
        width: 50%;
      }
      #{$es-form-field-control-selector} {
        width: 50%;
      }
    }
    
    // form field ratio: label 30%, control 70%
    &.-es-field-ratio-30-70 {
      #{$es-form-field-label-selector} {
        width: 30%;
      }
      #{$es-form-field-control-selector} {
        width: 70%;
      }
    }

    // form field ratio: label 25%, control 75%
    &.-es-field-ratio-25-75 {
      #{$es-form-field-label-selector} {
        width: 25%;
      }
      #{$es-form-field-control-selector} {
          width: 75%;
      }
    }
    
    // form field ratio: label 20%, control 80%
    &.-es-field-ratio-20-80 {
      #{$es-form-field-label-selector} {
        width: 20%;
      }
      #{$es-form-field-control-selector} {
        width: 80%;
      }
    }

    // control/component specific adaptions when used in form

    // Recaptcha alignment with form controls
    & > .g-recaptcha {
      float: right;
    }

    // adaptions for textboxes
    & .es-textbox {
      // Variable for textbox
      $es-textbox-vertical-padding: ($es-control-height - $es-line-height) * .5;
      
      // fill space up on bottom only so that label and read only text start
      // on same height
      padding-top: es-to-rem($es-textbox-vertical-padding);
  
    }
  
  }
}

// mobile-large upwards
// Needs to be after the first viewport selector, otherwise the inline group does not work, because it would be overwritten
@include es-viewport-range(mobile-large-up){
  .es-form-field .es-group {
    // default inline modifier
    &.-es-inline {
      #{$es-form-field-control-selector} {
        //display: inline-block;
        float: left;
        width: auto;
      }
    }
    // TODO needs rafctoring: Should be cleaned up in group implementation
    // special handling only when modified -es-inline-control is used.
    // enable flexbox when > mobile large
    &.-es-inline-combined {
      display: flex;
    }
  }

  .es-form-field .es-form-control-overlay {
    #{$es-form-field-control-selector} {
      width: 100%;
    }
  }

}


// Form legend, component only usable in form context
.es-form-legend {
  @include es-dl-inline();
  font-size: es-to-rem(12px);
  line-height: 1.5em;  //set to 18px
  margin: 0;
  .es-required {
    @extend %es-control-required-sign;
    font-size: es-to-rem(13px);
  }
}
