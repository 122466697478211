
//// ----------------------------------------------------------------------
///
/// Placeholder Component
///
/// @group component
/// @module sass/components/containers/placeholder
///
//// ----------------------------------------------------------------------

.es-placeholder {
  $es-placeholder-border: $es-container-border-width dashed $es-border-color;

  // Background color and border
  @extend %es-container-background;
  border: $es-placeholder-border;

  // Inset spacing
  @extend %es-container-inset-modifiers;
  // flex box container
  @extend %es-flex-container-dynamic;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  .es-content-box {
    justify-content: center;
    color: es-color('clario-mono','gray-11');
    &:hover{
      color: es-color('clario-mono','gray-12');
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.-es-inline {
    display: inline-flex;
    vertical-align: middle;
  }
}