//// ----------------------------------------------------------------------
///
/// Button Group Component
///
/// @group component
/// @module sass/components/basics/button-group
///
/// Button Group for joining buttons and button toggle and displaying them in a row
///
//// ----------------------------------------------------------------------

// Styles for toggle buttons
.es-button-group,
.es-button-group-vertical {

  input {
    //don't use display: none or visibility: hidden. Otherwise the checkbox will not be focusable
    //and also not navigable using TAB
    clip: rect(0,0,0,0);
    appearance: none;
    position: absolute;
  }

  input + label {
    @extend .es-button;
    border-radius: 0px;
  }

  input + label:hover {
    cursor: pointer;
  }

  input:checked + label {
      color: es-color("clario-purple","purple-12");
      background-color: es-color("clario-purple","purple-5");
  }
}

.es-button-group {
  display: inline-flex;

  // For Buttons in a button group
  & > .es-button {
    display: inline-block;
    position: relative;
  }

  & .es-button + .es-button {
    border-inline-start: none;
    border-radius: 0px;
    &:active, &:focus {
      border-inline-start: 1px solid $es-button-default-focus-border-color;
      margin-inline-start: -1px;
    }
  }

  // For button toggles in a horizontal button group
  input + label {
    border-left: none;
  }

  label:first-of-type {
      border-left: 1px solid $es-button-default-border-color;
      border-radius: $es-button-border-radius 0px 0px $es-button-border-radius !important;
  }
  
  & :first-child{
    border-radius: $es-button-border-radius 0px 0px $es-button-border-radius !important;
  }

  & :last-child{
    border-radius: 0px $es-button-border-radius $es-button-border-radius 0px !important;
    }
}

.es-button-group-vertical {
  display: inline-block;
  float: none;
  position: relative;
  width: auto;

  & > button,
  input + label {
    display: block;
    width:100%;
  }

  & > .es-button + .es-button {
    border-block-start: none;
    border-radius: 0px;
    &:active, &:focus {
      border-block-start: 1px solid $es-button-default-focus-border-color;
      margin-block-start: -1px;
    }
  }

  // For button toggles in a vertical button group
  input + label {
    border-top: none;
  }

  label:first-of-type {
    border-top: 1px solid $es-button-default-border-color;
    border-radius: $es-button-border-radius $es-button-border-radius 0px 0px !important;

  }

  & :first-child {
    border-radius: $es-button-border-radius $es-button-border-radius 0px 0px !important;
  }

  & :last-child {
    border-radius: 0px 0px $es-button-border-radius $es-button-border-radius !important;
  }
}