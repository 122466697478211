//// ----------------------------------------------------------------------
///
/// Legend list
///
/// @group configuration
/// @module sass/components/basics/legend-list
///
/// @require function es-color
//// ----------------------------------------------------------------------

.es-legend-list {
  @extend %es-bullet-list;
  @extend %es-bullet-default-color-modifiers;
  
  li {
    .es-icon {
      padding: es-to-rem($es-space-gutter-right-small);
    }
  }

  &:not(:last-child) {
    margin-bottom: es-to-rem($es-space-default);
  }
}
  
.es-legend-title {
  font-weight: bold;
  padding: es-to-rem($es-space-stack-small);
}