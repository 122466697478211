//// ----------------------------------------------------------------------
///
/// Badge basic component
///
/// @group components
/// @module sass/components/basics/badge
///
//// ----------------------------------------------------------------------

.es-badge {

  // Configuration variables for badges
  $es-badge-background-color: es-color('clario-mono', 'gray-9');
  $es-badge-text-color: $es-font-bright-color;
  $es-badge-line-height: es-to-rem(16px);
  $es-badge-border-radius: es-to-rem(26px);
  
  background-color: $es-badge-background-color;
  border-radius: $es-badge-border-radius;
  color: $es-badge-text-color;
  display: inline-block;
  
  line-height: $es-badge-line-height;
  min-width: es-to-rem(26px);
  padding: es-to-rem($es-space-inset-squish-default);
  text-align: center;

  & > .es-icon {
    margin-right: es-to-rem($es-space-small);
  }

  &.-es-positive{
    background-color: es-color('clario-teal','teal-9');
  }

  &.-es-negative{
    background-color: es-color('clario-red', 'red-9');
  }

  &.-es-notice{
    background-color: es-color('clario-yellow','yellow-9');
    color: $es-font-color;
  }

  &.-es-informative{
    background-color: es-color('clario-blue', 'blue-9');
  }

  &.-es-brand{
    background-color: es-color('clario', 'purple-d');
  }
}
