//// ----------------------------------------------------------------------
///
/// Date/Time field item for Date picking, Date picker pop up is external
/// library
/// @group partials
/// @module sass/components/form-controls/date-input

//// ----------------------------------------------------------------------

// Variables for date/time input

// padding is inset squish, but has to be calculated manually
// because of the caret at the right
$es-date-input-disabled-color: $es-control-border-color-disabled;

// Extension for date/time input
%es-date-time-input {


  display: inline-block;
  color: $es-control-icon-color;
  border-radius: $es-control-border-radius;

  &.-es-invalid {
    & > input {
      @extend %es-control-invalid;
    }
  }

  &.-es-disabled {
    & > input {
      @extend %es-control-disabled;
    }
    &:after {
      color: $es-date-input-disabled-color;
    }
  }

  & > input {
    @extend %es-input;
  }
}


.es-date-input {
  // Date input variables
  $date-input-inset-right: $es-icon-size-default + 2*$es-space-default;
  $date-input-inset: $es-space-small $date-input-inset-right $es-space-small $es-space-medium;

  @extend %es-date-time-input;

  & > input {
    padding: es-to-rem($date-input-inset);
    width: 100%;
    background-color: $es-control-field-background-color;
    border-radius: $es-control-border-radius;
  }

  // attach calendar icon to parent div
  @include es-control-icon-after(calendar);
}

.es-time-input {
  //Variables
  $es-time-input-button-width: es-to-rem($es-icon-size-default + 2px + ($es-space-default * 2) + $es-space-default);

  @extend %es-date-time-input;

  input {
    width: calc(100% - #{$es-time-input-button-width});
  }

  button {
    @extend %es-input;
    color: $es-control-icon-color;

    margin-left: es-to-rem($es-space-default);

    &:hover {
      @extend %es-control-hover;
    }
  }
}