//// ----------------------------------------------------------------------
///
/// mixins, extensions, functions or placeholder for browser specific changes
/// or "hacks"
///
/// @group modules
/// @module sass/modules/core/browser-specifics
//// ----------------------------------------------------------------------

// IE specific selector reset (hack), use to only target IE10/11
@mixin IE-10-11-only() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content    
    }
}