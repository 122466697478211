//// ----------------------------------------------------------------------
///
/// Modules for page components (templates)
///
/// @group modules
/// @module sass/modules/templates/page-footer
///
//// ----------------------------------------------------------------------

// Footer global Variables
$es-page-footer-border: 1px solid $es-border-light-color;

// Page footer extension for usage in templates (with before space reservation)
%es-page-footer {
  //footer Variables
  $es-page-footer-height: 1 * $es-space-default + $es-space-small + 2* $es-line-height;
  $es-page-footer-height-mobile: 3 * $es-space-default + 2* $es-line-height;

  &:before {
    content: '';
    display: block;
    height: es-to-rem($es-page-footer-height + $es-space-large);
  }
  .es-page-footer-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    height: es-to-rem($es-page-footer-height-mobile);
    left: 0;
    padding: es-to-rem($es-space-inset-small);
    position: absolute;
    right: 0;
   
    @extend %es-link-list;

    p, nav ul {
      margin: 0;
    }

    @include es-viewport-range(mobile-large-up){
      height: es-to-rem($es-page-footer-height);
      padding: es-to-rem($es-space-inset-default);
    }
  }
}