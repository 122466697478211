//// ----------------------------------------------------------------------
///
/// Grid
///
/// @group grid
/// @module sass/components/grid

//// ----------------------------------------------------------------------

.es-grid {
  $es-grid-border-color: $es-border-light-color;
  $es-grid-spacing-correction: $es-space-small * -1;

  background-color: $es-background-color;
  border: 1px solid $es-grid-border-color;

  .es-grid-header {
    @extend %es-container-inset-modifiers;
    border-bottom: 1px solid $es-grid-border-color;
  }

  .es-grid-body {
      // Inset spacing
      @extend %es-container-inset-modifiers;

    .es-table {
      caption,
      th,
      td {
        vertical-align: top;
      }
    }
  }
  
  .es-collapsible-panel {
      border: none;

      margin: 0 es-to-rem($es-grid-spacing-correction);

      &:first-child {
        margin-top: es-to-rem($es-grid-spacing-correction);
      }
      &:last-child {
        margin-bottom: es-to-rem($es-grid-spacing-correction);
      }
  }

  .es-collapsible-panel + .es-collapsible-panel {
    border-top: 1px solid $es-grid-border-color;
  }
}

.es-grid-info {
  ul, li {
    display: inline;
    margin: 0;
    padding: 0;
  }

  li + li {
    &:before {
      content: ' | ';
    }
  }
}

.es-grid-badges {
  // Vertical align baseline for alignment in collapsible header
  .es-badge-label {
    vertical-align: baseline;
  }
  //smaller badge
  .es-badge {
    background-color: $es-badge-data-color;
    font-size: .75em;
  }
}

@include es-viewport-range(tablet-large-up) {
  .es-grid {

    .es-grid-title {
      width: 33.3%;
      padding: 0;
    }

      .es-collapsible-panel-header {
        &,
        .es-grid-info ul,
        .es-grid-badges ul {
          display: table;
          width: 100%;
          button[data-toggle]{
            color: es-color('clario-mono','gray-12');
            &:hover {
              color: es-color('clario-mono','gray-12');
            }
          }
        }

        .es-grid-title,
        .es-grid-info,
        .es-grid-info li,
        .es-grid-badges {
          display: table-cell;
          padding-left: .75em;
        }

        li {
          // hide '|' on larger screens
          &:before {
            display: none;
          }
        }

        .es-grid-badges {
          text-align: right;
        }

        // Width modifiers for grid elements
        .es-grid-badges,
        .es-grid-title,
        .es-grid-badges {
          @include es-width-create-modifiers(12, "-es-width-");
        }
      }
    }
  }


@include es-viewport-range(desktop-up) {
  .es-grid {
    .es-grid-title {
      width: 40%;
    }
  }
}
