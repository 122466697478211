
//// ----------------------------------------------------------------------
///
/// Switch form control component
///
/// @group components
/// @module sass/components/form-controls/switch
///
//// ----------------------------------------------------------------------

.es-switch {
  $switch-height: 24px; // height of switch
  $switch-min-width: 2*$switch-height; // minimum width of switch (i.e. without text)
  $switch-border-radius: 20px;
  $switch-border-width: 1px;

  // variables for generic toggle
  $switch-toggle-spacing: 2px;
  $switch-toggle-padding: $switch-toggle-spacing $es-space-small;
  $switch-toggle-size: $switch-height - 2*$switch-toggle-spacing - 2*$switch-border-width; // calculation = switch size - 2 * space (top & bottom) - 2px (border top & bottom)
  $switch-toggle-border-radius: 50%;
  $switch-toggle-border-color: es-color('clario-mono', 'gray-6');
  $switch-toggle-button-color: es-color('clario-mono','gray-9');
  $switch-toggle-text-padding: $es-space-xsmall $es-space-medium;

  // variables for toggle state "on"
  $switch-toggle-on-background-color: es-color("clario",'purple-d'); //#86d993;
  $switch-toggle-on-text-color: es-color("clario-mono", "white");
  $switch-toggle-on-padding: $switch-toggle-size + $switch-toggle-spacing; // -2px due to space on right
  
  // variables for toggle state "off"
  $switch-toggle-off-background-color: es-color("clario-mono", "mono-12");
  $switch-toggle-off-text-color: es-color("clario-mono", "white");
  $switch-toggle-off-padding: $switch-toggle-size + $switch-toggle-spacing; // -2px due to space on right

  // variables for toggle state disabled (on & off)
  $switch-toggle-on-disabled-background-color: es-color('clario', 'purple-d');
  $switch-toggle-off-disabled-background-color: es-color('clario-mono', 'white');
  $switch-toggle-disabled-text-color: es-color('clario-mono', 'gray-6');

  display: inline-block;
  outline: 0;
  position: relative;

  .es-switch-toggle {
    @extend %es-control-basic;

    border-radius: es-to-rem($switch-border-radius);
    border-color: $switch-toggle-border-color;
    cursor: pointer;
    // IE specific setting: 
    // Controls look odd in height in IE, when not set
    height: es-to-rem($switch-height);
    line-height: 1em; // reduce line-height to font-size
    min-width: es-to-rem($switch-min-width);
    overflow: hidden;
    position: relative;
    padding: es-to-rem($switch-toggle-padding);
    vertical-align: middle;
    transition:
      color 0.3s ease,
      padding 0.3s ease-in-out,
      background 0.3s ease-in-out;

    // text on switch
    &:before {
      content: '';
      display: inline-block;
      line-height: 1em;
      font-size: 1em;
      padding: es-to-rem($switch-toggle-text-padding);
      position: relative;
      vertical-align: middle;
    }

    // toggle
    &:after {
      border-radius: $switch-toggle-border-radius;
      box-shadow: 0 0 .4rem rgba(0, 0, 0, .3);
      content: '';
      display: inline-block;
      height: $switch-toggle-size;
      left: 0;
      margin-left: es-to-rem($switch-toggle-spacing);
      position: absolute;
      width: $switch-toggle-size;
      transition:
        left 0.3s cubic-bezier( 0.455, 0.030, 0.515, 0.955 ),
        background 0.3s ease-in-out;  
    }

    &:hover {
      @extend %es-control-hover;
    }
  }

  // all switch labels
  .es-switch-label {
    vertical-align: middle;
  }

  input[type="checkbox"] {
    // don't display checkbox control from OS,
    // but don't use "display: none" or "visibility: hidden". Otherwise the checkbox will not be focusable
    // and also not be navigatable using TAB
    appearance: none;
    clip: rect(0,0,0,0);
    position: absolute;

    // toggle in "Off" state
    &:not(:checked) {
      & + .es-switch-toggle {
        background: $switch-toggle-off-background-color;
        color: $switch-toggle-off-text-color;
        padding-left: es-to-rem($switch-toggle-off-padding);
        padding-right: 0;

        // text on switch
        &:before {
          content: attr(data-off);
          color: $switch-toggle-off-text-color;
        }

        // toggle
        &:after {
          background: $switch-toggle-button-color;
        }
      }
    }

    // toggle in "On" state
    &:checked {
      & + .es-switch-toggle {
        background: $switch-toggle-on-background-color;
        color: $switch-toggle-on-text-color;
        padding-left: 0;
        padding-right: es-to-rem($switch-toggle-on-padding);

        // text on switch
        &:before {
          content: attr(data-on);
          color: $switch-toggle-on-text-color;
        }

        // toggle
        &:after {
          background: $switch-toggle-button-color;
          left: calc(100% - #{es-to-rem($switch-toggle-on-padding + $switch-toggle-spacing)} );
        }
      }
    }

    // when (hidden) checkbox has focus
    &:focus + .es-switch-toggle {
      @extend %es-control-focus;
    }

    // disabled state
    &:disabled, 
    &:read-only {
      & + .es-switch-toggle {
        @extend %es-control-disabled;
        // text on switch
        &:before {
          color: $switch-toggle-disabled-text-color;
        }
        // background of switch toggle (when off)
        &:after {
          background: $switch-toggle-off-disabled-background-color;
        }
      }

      // background of switch toggle (when om)
      &:checked + .es-switch-toggle {
        background: $switch-toggle-on-disabled-background-color;
      }
    }
  }

  // invalid state
  &.-es-invalid {
    & input[type="checkbox"] + .es-switch-toggle {
      @extend %es-control-invalid;
    }
  }
}
