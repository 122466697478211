//Config: path to directory for images and fonts
$es-path-to-assets: '../';

// version header for styles
//-------------------------------
//-------------------------------
@if variable-exists(app-version) {
	/*!
	 * Custom Application compiled CSS styles V#{$app-version}
	 */
} @else {
	/*!
	 * ert-webstyle CSS UI Toolkit V${VERSION}
	 */
}

// configuration
//-------------------------------
//-------------------------------
@import "configuration";


// mixins and functions
//-------------------------------
//-------------------------------
@import "modules";


//Webfonts
//-------------------------------
//-------------------------------
@import 'foundation/webfonts';

// Element styles
//-------------------------------
//-------------------------------
// normalize.css v4 reset styles
//-------------------------------
// We have this file to assure compatibility with bootstrap.
// (you can remove it if you use bootstrap 3 or 4 along with ert-webstyles)

@import "vendor/normalize";
@import "foundation/general";

@import "foundation/typography";
@import "foundation/content-box";
@import "foundation/icon";
@import "foundation/invisible";
@import "foundation/layouts/layouts";
@import "foundation/layouts/layout-base";
@import "foundation/layouts/layout-columns";
@import "foundation/layouts/layout-grid";
@import "foundation/layouts/layout-flex-list";
@import "foundation/widths";

@import "components/alerts/inline-message";
@import "components/alerts/message-banner";
@import "components/alerts/notification";
@import "components/alerts/notification-center";

@import "components/basics/actionbar";
@import "components/basics/badge";
@import "components/basics/badge-label";
@import "components/basics/bullet-list";
@import "components/basics/legend-list";
@import "components/basics/button";
@import "components/basics/button-group";
@import "components/basics/dropdown";
@import "components/basics/link-list";
@import "components/basics/tag";

@import "components/containers/card";
@import "components/containers/collapsible-panel";
@import "components/containers/panel";
@import "components/containers/popover";
@import "components/containers/popup";
@import "components/containers/section";
@import "components/containers/section-box";
@import "components/containers/tabs";
@import "components/containers/toolbar";
@import "components/containers/tour";
@import "components/containers/widget";
@import "components/containers/placeholder";
@import "components/containers/action-button-bar";

@import "components/form-controls/date-input";
@import "components/form-controls/date-picker";
@import "components/form-controls/form-controls";
@import "components/form-controls/form-control-overlay";
@import "components/form-controls/switch";

@import "components/forms/form";
@import "components/forms/form-field";
@import "components/forms/form-message";

@import "components/grid/grid";

@import "components/indicators/loading";
@import "components/indicators/status-sign";
@import "components/indicators/tooltip";
@import "components/indicators/wizard-steps";

@import "components/navigation/breadcrumbs";
@import "components/navigation/nav-item";
@import "components/navigation/pagination";
@import "components/navigation/quicklinks";
@import "components/navigation/sidebar";

@import "components/tables/data-type-alignment";
@import "components/tables/table";
@import "components/tables/table-legend";

@import "components/file-item";
@import "components/product";

@import "templates/app/app";
@import "templates/app/app-with-sidebar";
@import "templates/app/page-header";
@import "templates/gateway/gateway";
@import "templates/gateway/gateway-column";

// Print styles are now in a separate file and have to be included as an extra css file

//Enable JS applications to detect current ui toolkit version
.es-uit-version::after{
  content: '${VERSION}';
}
// kept for backward compatibility reasons
.es-sg-version::after{
  content: '${VERSION}';
}
