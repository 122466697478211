//// ----------------------------------------------------------------------
///
/// Table organism
///
/// @group configuration
/// @module sass/organisms/table
///
/// @require function es-color
//// ----------------------------------------------------------------------

// Variables for tables
$es-table-cell-padding:           es-to-rem($es-space-inset-small);
$es-table-header-sort-padding:    es-to-rem($es-space-inset-small + $es-space-gutter-small + ($es-icon-size * .95));
$es-table-side-padding:           es-to-rem($es-space-default);
$es-table-sorting-width:          es-to-rem(4px);
$es-table-status-sign-padding:    es-to-rem($es-space-default + $es-space-small $es-space-default);

// Colors
$es-table-header-color:           es-color("clario-mono","gray-2");
$es-table-header-subtle-color:    es-color("clario-mono", "gray-2");
$es-table-border-color-inner:     es-color("clario-mono","gray-5");
$es-table-border-color:           $es-border-color;
$es-table-background-color:       es-color("clario-mono", "gray-2");
$es-table-hover-color:            es-color("clario-mono", "gray-4");
$es-table-selected-hover-color:   es-color("clario-purple", "purple-4");
$es-table-selected-color:         es-color("clario-purple", "purple-3");
$es-table-selected-border-color:  es-color("clario", "purple-d");
$es-table-selected-border-block-color: es-color("clario-purple", "purple-5");

// Table sorting mixin
@mixin es-table-sorting-icon($icon, $size: $es-icons-font-size-base){
  @include es-icon(ert-icons);
  content: es-get-icon($icon);
  font-size: es-em-calc($size);
  margin: es-to-rem($es-space-gutter-left-small);
  pointer-events: none;
  position: absolute;
}

.es-table {

  border-collapse: collapse;

  // Column width modifiers
  @include es-width-create-modifiers(12, "-es-width-");


  caption,
  td {
    padding: $es-table-cell-padding;
    vertical-align: middle;
  }

  th {
    padding: $es-table-cell-padding;
    vertical-align: bottom;
    font-weight: normal;
  }

  tr.-es-valign-top,
  td.-es-valign-top,
  th.-es-valign-top {
    vertical-align: top;
  }

  // Spacing for status sign in table
  td .es-status-sign {
    padding: $es-table-status-sign-padding;
  }

  &.-es-plain {
    & > thead {
        background-color: $es-table-header-subtle-color;
        border: 1px solid $es-table-header-subtle-color;
        color: es-color("mono", "black");
    }
  }

  thead {
    background-color: $es-table-header-color;
    border-top: 1px solid $es-table-border-color;
    border-bottom: 1px solid $es-table-border-color;
    color: $es-font-color;
    font-size: $es-small-font-size;

    th {
      position: relative;
    }
    th:first-child {
      padding-left: $es-table-side-padding;
    }
    th:last-child:not(.-es-sort):not(.-es-sort-descending):not(.-es-sort-ascending) {
      padding-right: $es-table-side-padding;
    }

    .-es-sort,
    .-es-sort-ascending,
    .-es-sort-descending {
      cursor: pointer;
      padding-right: $es-table-header-sort-padding;
      position: relative;
    }
    .-es-sort {
      &:after {
        @include es-table-sorting-icon(sorting, 9.5);
      }
    }
    .-es-sort-ascending {
      &:after {
        @include es-table-sorting-icon(sorting-ascending, 9.5);
      }
    }
    .-es-sort-descending {
      &:after {
        @include es-table-sorting-icon(sorting-descending, 9.5);
      }
    }
  }

  .es-icon {
    color: $es-icon-color;
    &:hover {
      color: $es-font-color;
    }
  }

  tbody {
    // @extend %es-container-background-with-border;
    &:last-child{
      border-bottom: 1px solid $es-table-border-color-inner;
    }

    &:first-child{
      border-top: 1px solid $es-table-border-color; 
    }
    
    tr {
      border-inline-start: 3px solid transparent;
      border-top: 1px solid $es-table-border-color-inner;
      & > th:first-child,
      & > td:first-child {
        padding-left: $es-table-side-padding;
      }
      & > td:last-child {
        padding-right: $es-table-side-padding;
      }

      a {
        color: $es-font-color;
        font-weight: normal;
        &:hover {
          color: $es-anchor-color;
        }
      }
      &:hover{
        a {
          text-decoration:underline;
        }
      }
    }
  }

  tbody tr:nth-child(even){
    background-color: $es-table-background-color;
  }

  &.-es-selectable {
    tbody tr.-es-selected {
      background-color: $es-table-selected-color;
      border-block: 1px solid $es-table-selected-border-block-color;
      border-inline-start: 3px solid $es-table-selected-border-color;


      // Extensions for table
        %es-table-selected-bar {
          background-color: es("clario-mono","gray-3");
          bottom: 0;
          content: "";
          position: absolute;
          top: 0;
          width: $es-table-sorting-width;
        }


      & > th,
      & > td {
        position: relative;
      }
      // & > *:first-child {
      //   &:before {
      //     @extend %es-table-selected-bar;
      //     left: 0;
      //   }
      // }
      // & > *:last-child {
      //   &:after {
      //     @extend %es-table-selected-bar;
      //     right: 0;
      //   }
      // }
    }
    tbody tr.-es-selected:hover{
      background-color: $es-table-selected-hover-color;
    }
    tbody tr:hover {
      background-color: $es-table-hover-color;
    }
    tbody {
      cursor: pointer;
    }
  }
  &.-es-full {
    width: 100%;
  }

  .es-fluid-data {
    @include es-clearfix;
  
    list-style: none;
    margin: 0;
    padding: 0;
  
    @include es-viewport-range(tablet-large-up){
      li{
        float: left;
        width: 50%;

        &:not(:last-child) {
          padding-right: es-to-rem($es-space-default);
        }

/*      IMPROVED SOLUTION:
        Which does not waste space at the end of 3nd Column. But this solution
        would be better in terms of implementation, when we would have a specific
        viewport range which should include everything from tablet to desktop for the 2 column settings, but not above.
        With the current viewport range settings, a reset of the :nth-child(odd) line, as
        the 2 column settings are inherited to desktop-up range resulting in an error.

        &:nth-child(odd) {
          padding-right: es-to-rem($es-space-default);
        }
*/
      }

    }
    @include es-viewport-range(desktop-up){
      li{
        width: 33.3%;

        &:not(:last-child) {
          padding-right: es-to-rem($es-space-default);
        }
/*
        IMPROVED SOLUTION:
        Which does not waste space at the end of 3nd Column. But this solution
        would be better in terms of implementation, when we would have a specific
        viewport range which should include everything from tablet to desktop for the 2 column settings, but not above.
        With the current viewport range settings, a reset of the :nth-child(odd) line, as
        the 2 column settings are inherited to desktop-up range resulting in an error.

        &:nth-child(odd) {
          padding-right: 0;
        }

        &:not(:nth-child(3n+3)) {
          padding-right: es-to-rem($es-space-default);
        }
    */
      }
    }
  }
}