/// ----------------------------------------------------------------------
///
/// general (type) styles
///
/// @group partials
/// @module sass/general
///
//// ----------------------------------------------------------------------

//
// Change from `box-sizing: content-box` to `border-box` so that when you add
// `padding` or `border`s to an element, the overall declared `width` does not
// change. For example, `width: 100px;` will always be `100px` despite the
// `border: 10px solid red;` and `padding: 20px;`.
//
// Heads up! This reset may cause conflicts with some third-party widgets. For
// recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing.
//
// Credit: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/

// extension for resetting
@mixin es-reset-box-sizing {
  box-sizing: border-box;
}

@mixin es-reset-body {
  background-color: $es-background-contrast-color;
  color: $es-font-color;
  font-size: es-to-rem($es-font-size-default);
  font-family: $es-font-primary;
  min-height: 100%;
  line-height: es-to-rem($es-line-height);
}


html {
  @include es-reset-box-sizing;

  min-height: 100%;
  // By default all browsers use a 16px font size, so
  // we re-calculate the default pixel size to a percentage for loss-less
  // calculation of later rem values
  font-size: es-percentage($es-base-unit, 16px); // => font size: 10 * 100 / 16 => 62.5%
}

body {
  @include es-reset-body;
}

*, *:before, *:after {
  @include es-reset-box-sizing;
}

//// ----------------------------------------------------------------------
///   Reset styles for host web component
//// ----------------------------------------------------------------------
:host > * {
  // apply body reset part
  @include es-reset-box-sizing;
  @include es-reset-body;

  all: initial;
  margin: 0;
  text-size-adjust: 100%;
}

//// ----------------------------------------------------------------------
///   Reset styles for UI Toolkit
//// ----------------------------------------------------------------------

// overriding user agent styles for table header
th {
  text-align: left;
}

