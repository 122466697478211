//// ----------------------------------------------------------------------
///
/// Notification center
///
/// @group components
/// @module sass/partials/components/alerts/notification-center
///
//// ----------------------------------------------------------------------

.es-notification-center {

  display: flex;
  flex-direction: column;
  overflow: visible;
  position: fixed;
  right: 0;
  top: es-to-rem($es-global-header-height);
  width: 100%;
  padding: es-to-rem($es-space-inset-small);


  .es-notification {
    margin: es-to-rem($es-space-inset-squish-default);
    order: 1;
    width: calc(100% - #{es-to-rem($es-space-default) * 2});

    &.-es-priority-high {
      order: 0;
    }

    &.-es-priority-normal {
      order: 1;
    }

    &.-es-priority-low {
      order: 100;
    }
  }

  &.-es-no-header {
    top: 0;
  }
  
  &.-es-left {
    .es-notification {
      left: 0;
    }
  }

  @include es-viewport-range('tablet-large-up') {
    &.-es-small {
      width: 50%;
      &.-es-center {
        margin: 0 50%;
      }
    }
  }

  @include es-viewport-range('desktop-up') {
    &.-es-small {
      width: 33.3%;
      &.-es-center {
        margin: 0 33.3%;
      }
    }
  }

}
