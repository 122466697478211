//// ----------------------------------------------------------------------
///
/// controls configuration file
///
/// @group configuration
/// @module sass/configuration/atoms/controls
///
//// ----------------------------------------------------------------------

// Colors
$es-control-border-color: es-color('clario-mono', 'gray-7');
$es-control-border-color-focus: es-color('clario-mono', 'gray-11');
$es-control-border-color-disabled: $es-disabled-color;
$es-control-border-color-hover: es-color('clario-mono', 'gray-11');
$es-control-border-color-invalid: es-color('clario-red', 'red-9');
$es-control-color: es-color('clario-mono', 'gray-12');
$es-control-icon-color: es-color('clario-mono', 'gray-11');
$es-control-field-background-color: es-color('clario-mono', 'gray-1');

// State colors
$es-control-disabled-color: $es-background-disabled-color;
$es-control-hover-color: es-color('clario-mono', 'gray-4');
$es-control-text-hover-color: $es-brand-main;
$es-control-required-color: es-color('clario-red', 'red-9');
$es-control-separator-color: es-color('clario-mono', 'black', .2);

// Shadow
$es-control-shadow-color-hover: $es-shadow-color;

// Opacity
$es-control-disabled-opacity: 0.6;

// Font
$es-control-font-family: $es-font-primary;

/// Variables for controls
////////////////////////////////////////////////////////
$es-control-border-width: 1px;
$es-control-height: $es-pixel-unit * 3.2;
$es-control-borderless-height: $es-control-height - 2*$es-control-border-width;
$es-control-line-height: $es-control-height - $es-space-default - 2*$es-control-border-width;
$es-control-borderless-line-height: $es-control-height - $es-space-default;

// Border
$es-control-separator-border: 1px solid $es-control-separator-color;
$es-control-border-radius: 3px;

// Box shadow
$es-control-shadow-focus: 0 0 4px #{$es-control-shadow-color-hover};

// Variables for checkbox and radio button
$es-checkbox-size: $es-font-size-default + 7px;

$es-checkbox-vertical-padding: ($es-control-height - $es-checkbox-size) * .5; // subtract border

// Variables for switch
$es-switch-size: 24px;
$es-switch-vertical-padding: ($es-control-height - $es-switch-size) * .5;

// Radio Button
$es-radio-bullet-color: $es-control-color;
