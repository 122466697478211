//// ----------------------------------------------------------------------
///
/// Product with thumbnail, for use in product-list
///
/// @group partials
/// @module sass/molecules/product

//// ----------------------------------------------------------------------

.es-product{

  .es-thumbnail{
    @extend %es-container-background-with-border;
    padding: es-to-rem($es-space-inset-default);
    position: relative;
    text-align: center;
    .es-logo{
      max-width: 100%;
    }
    .es-info {
      position: absolute;
      right: es-to-rem($es-space-default);
      top: es-to-rem($es-space-default);
      white-space: nowrap;
      .es-icon {
        font-size: es-to-rem($es-icon-size-large);
        vertical-align: top;
      }
    }
  }
  .es-button{
    width: 100%;
  }
}