//// ----------------------------------------------------------------------
///
/// Table-Legend organism
///
/// @group configuration
/// @module sass/organisms/table
///
/// @require function es-color
//// ----------------------------------------------------------------------

// specific styling for table legend
.es-table-legend {
    // configuration
    $es-table-legend-text-default-color: $es-font-color;
  
    // Background color and border
    @extend %es-container-background;
    // Inset spacing
    @extend %es-container-inset-modifiers;
  
    // set default color
    color: $es-table-legend-text-default-color;
    
    width: 100%;

    // for stacking (direct) children
    & > * {
        &:not(:last-child) {
            margin: es-to-rem($es-space-stack-large);
        }
    }
    
    // border around table legend container
    &.-es-border {
        @extend %es-container-background-with-border;
    }

    // aligned width settings when 2-column layout included
    @include es-viewport-range(mobile-large-up) {
        &.-es-aligned {
            .es-layout-columns {
                .es-column:nth-child(1) {
                    width: 30%;
                }
                .es-column:nth-child(2) {
                    width: 70%;
                }
            }
        }
    }
}
