//// ----------------------------------------------------------------------
///
/// miscellaneous utility module for flexbox related functionality
///
/// @group modules
/// @module sass/modules/core/flexbox
///
//// ----------------------------------------------------------------------

%es-flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
  
%es-flex {
    display: flex;
}
  
//// ----------------------------------------------------------------------
///
/// Mixins for easier flexbox use
///
@mixin es-flex-size-scalable {
    flex-grow: 1;
    flex-shrink: 1;
}

@mixin es-flex-size-static {
    flex-grow: 0;
    flex-shrink: 0;
}

@mixin es-flex-flow-column {
    flex-flow: column nowrap;
}

@mixin es-flex-flow-row {
    flex-flow: row nowrap;
}

@mixin es-flex-flow-fluid-column {
    flex-flow: column wrap;
}

@mixin es-flex-flow-fluid-row {
    flex-flow: row wrap;
}


//// ----------------------------------------------------------------------
///
/// Predefined flexbox elements as extension classes
///
%es-flex-container-dynamic {
    @include es-flex-size-scalable;
    align-items: stretch;
    display: flex;
}

%es-flex-container-static {
    display: block;
    @include es-flex-size-static;
}


