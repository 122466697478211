//// ----------------------------------------------------------------------
///
/// Tooltip Component
///
/// @group indicators
/// @module sass/components/indicators/tooltip
///
//// ----------------------------------------------------------------------


// Variables for Tooltip
$es-tooltip-width: 160px;
$es-tooltip-mobile-width: 100px;
$es-tooltip-half-height: calc(($es-line-height + (2 * $es-space-small)) / 2);
$es-tooltip-border-radius: 4px;

$es-tooltip-background-color: es-color("clario-mono", "gray-12");
$es-tooltip-text-color: es-color("mono", "white");

$es-tooltip-light-background-color: es-color("clario-mono", "gray-1");
$es-tooltip-light-text-color: $es-font-color;

// Nibbon variables
$es-tooltip-nibbon-border-size: 6px;
$es-tooltip-nibbon-size: ($es-tooltip-nibbon-border-size*2);

/* Base styles for the element that has a tooltip */
[data-tooltip],
.es-tooltip {
  cursor: pointer;
  position: relative;
}

/* Base styles for the entire tooltip */
[data-tooltip],
.es-tooltip {
  &:after,
  &:before {
    border-color: none;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    transition:         
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    visibility: hidden;
  }
}

/* Show the entire tooltip on hover, focus and status modifier */
[data-tooltip]:hover,
[data-tooltip]:focus,
.-es-open.es-tooltip {
  &:after,
  &:before {
    opacity: 1;
  visibility: visible;
  }
}

// Hide if close modifier is present!
.-es-close.es-tooltip {
  &:after,
  &:before {
    display: none;
  }
}

/* Base styles for the tooltip's directional arrow */
.es-tooltip,
[data-tooltip] {
  &:before {
    background: transparent;
    border: es-to-rem($es-tooltip-nibbon-border-size) solid transparent;
    content: "";
    // Refactor with mixin in next version
    z-index: 1001;
  }
}

/* Base styles for the tooltip's content area */
.es-tooltip,
[data-tooltip] {
  &:after {
    background-color: $es-tooltip-background-color;
  color: $es-tooltip-text-color;
  content: attr(data-tooltip);
  padding: es-to-rem($es-space-inset-squish-default);
  width: es-to-rem($es-tooltip-mobile-width);
  border-radius: $es-tooltip-border-radius;

  @include es-viewport-range('desktop-up') {
    width: es-to-rem($es-tooltip-width);
  }

  // Refactor with mixin in next version
  z-index: 1000;
  }
}

// Light color style for tooltip and all other positional variants
.-es-light.es-tooltip,
.-es-light.es-tooltip-bottom,
.-es-light.es-tooltip-left,
.-es-light.es-tooltip-right {
  &:after {
    background-color: $es-tooltip-light-background-color;
    color: $es-tooltip-light-text-color;
  }
}

/* Directions */

/* Top (default) */
[data-tooltip],
.es-tooltip {
  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
  }
}

[data-tooltip],
.es-tooltip {
  &:before {
    border-top-color: $es-tooltip-background-color;
    margin-bottom: es-to-rem(-($es-tooltip-nibbon-size));
    margin-left: es-to-rem(-($es-tooltip-nibbon-border-size));
  }
}

// Light color style for nibbon
.-es-light.es-tooltip:before {
  border-top-color: $es-tooltip-light-background-color;
}

/* Horizontally align top/bottom tooltips */
[data-tooltip],
.es-tooltip {
  &:after {
    margin-left: es-to-rem(-(calc($es-tooltip-mobile-width / 2)));

    @include es-viewport-range('desktop-up') {
      margin-left: es-to-rem(-(calc($es-tooltip-width / 2)));
    }
  }
}

[data-tooltip]:hover,
[data-tooltip]:focus,
.es-tooltip:hover,
.es-tooltip:focus,
.-es-open.es-tooltip {
  &:after,
  &:before {
    transform: translateY(es-to-rem(-($es-tooltip-nibbon-size))); 
  }
}

/* Bottom */
.es-tooltip-bottom {
  &:after,
  &:before {
    bottom: auto;
    left: 50%;
    top: 100%;
  }
}

.es-tooltip-bottom:before {
  border-bottom-color: $es-tooltip-background-color;
  border-top-color: transparent;
  margin-bottom: 0;
  margin-top: es-to-rem(-($es-tooltip-nibbon-size));
}

// Light color style for nibbon
.-es-light.es-tooltip-bottom:before {
  border-bottom-color: $es-tooltip-light-background-color;
}

.es-tooltip-bottom:hover,
.es-tooltip-bottom:focus {
  &:after,
  &:before {
    transform: translateY(es-to-rem($es-tooltip-nibbon-size)); 
  }
}

/* Left */
.es-tooltip-left {
  &:after,
  &:before {
    bottom: 50%;
    left: auto;
    right: 100%;
  }
}

.es-tooltip-left:before {
  border-left-color: $es-tooltip-background-color;
  border-top-color: transparent;
  margin: 0 es-to-rem(-($es-tooltip-nibbon-border-size*2)) 0 0;  
}

// Light color style for nibbon
.-es-light.es-tooltip-left:before {
  border-left-color: $es-tooltip-light-background-color;
}

.es-tooltip-left:hover,
.es-tooltip-left:focus {
  &:after,
  &:before {
    transform: translateX(es-to-rem(-($es-tooltip-nibbon-size)));
  }
}

/* Right */
.es-tooltip-right {
  &:after,
  &:before {
    bottom: 50%;
    left: 100%;
  }
}

.es-tooltip-right:before {
  border-right-color: $es-tooltip-background-color;
  border-top-color: transparent;
  margin-bottom: 0;
  margin-left: es-to-rem(-($es-tooltip-nibbon-size));
}

// Light color style for nibbon
.-es-light.es-tooltip-right:before {
  border-right-color: $es-tooltip-light-background-color;
}

.es-tooltip-right:hover,
.es-tooltip-right:focus {
  &:after,
  &:before {
    transform: translateX(es-to-rem($es-tooltip-nibbon-size)); 
  }
}

/// Styles for right and left

/* Center directional arrows for left/right tooltips (1px is for border of elements with control height) */
.es-tooltip-left,
.es-tooltip-right {
  &:before {
    top: es-to-rem((calc($es-tooltip-half-height / 2)) + 1px);
  }
}

/* Vertically center tooltip content (half of the height of element) for left/right tooltips */
.es-tooltip-left,
.es-tooltip-right {
  &:after {
    margin-bottom: es-to-rem(-($es-tooltip-half-height));
    margin-left: 0;
  }
}