//// ----------------------------------------------------------------------
///
/// Section box
///
/// @group partials
/// @module sass/components/containers/section-box
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

.es-section-box {
  @extend %es-content-box-inset-modifiers;

  margin: 0 es-to-rem($es-space-small * -1);

  &:first-child {
    margin-top: es-to-rem($es-space-small * -1);
  }
  &:last-child {
    margin-bottom: es-to-rem($es-space-small * -1);
  }

  &.-es-bg-controls {
    background-color: es-color("clario-mono", "gray-3");
  }

  &.-es-bg-controls-accent {
    background-color: es-color("clario-mono", "white");
  }

  &.-es-bg-data {
    background-color: es-color("clario-purple", "purple-brand");
    color: es-color("mono", "white");
  }
  
  &.-es-bg-data-accent {
    background-color: es-color("clario", "purple-d");
    color: es-color("mono", "white");
  }

}