/* ERT Icon Font */
@font-face {
  font-family: "ert-icons";
  src:url("#{$es-path-to-assets}fonts/ert-icons/ert-icons.eot");
  src:url("#{$es-path-to-assets}fonts/ert-icons/ert-icons.eot?#iefix") format("embedded-opentype"),
  url("#{$es-path-to-assets}fonts/ert-icons/ert-icons.woff") format("woff"),
  url("#{$es-path-to-assets}fonts/ert-icons/ert-icons.ttf") format("truetype"),
  url("#{$es-path-to-assets}fonts/ert-icons/ert-icons.svg#ert-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Inter - Black */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-Black.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Black.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Black.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-Black.otf') format('opentype')
}

/* Inter - Extra Bold */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraBold.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraBold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraBold.otf') format('opentype')
}

/* Inter - Bold */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Bold.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-Bold.otf') format('opentype')
}

/* Inter - Semi Bold */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-SemiBold.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-SemiBold.otf') format('opentype')
}

/* Inter - Medium */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Medium.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-Medium.otf') format('opentype')
}

/* Inter - Regular */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Regular.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-Regular.otf') format('opentype')
}

/* Inter - Light */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-Light.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Light.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Light.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-Light.otf') format('opentype')
}

/* Inter - Extra Light */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraLight.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraLight.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraLight.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-ExtraLight.otf') format('opentype')
}

/* Inter - Thin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: local('Inter'), local('Inter'),
  url('#{$es-path-to-assets}fonts/inter/Inter-Thin.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Thin.woff') format('woff'), /* Modern Browsers */
  url('#{$es-path-to-assets}fonts/inter/Inter-Thin.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$es-path-to-assets}fonts/inter/Inter-Thin.otf') format('opentype')
}