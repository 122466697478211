//// ----------------------------------------------------------------------
///
/// Button component
///
/// @group components
/// @module sass/components/basics/button
///
//// ----------------------------------------------------------------------

// Global variables
  // standard button settings
  $es-button-line-height:                           es-to-rem($es-line-height) !default;
  $es-button-icon-size:                             es-to-rem($es-icon-size) !default;
  $es-button-border-radius:                         4px !default;

  // default button colors
    /// enabled state
    $es-button-default-background-color:            es-color('mono', 'white') !default;
    $es-button-default-text-color:                  es-color('clario-mono', 'gray-12') !default;
    $es-button-default-border-color:                es-color('clario-mono', 'gray-7') !default;
    $es-button-default-box-shadow-color:            es-color("mono", "black", .2) !default;
    /// hover state
    $es-button-default-hover-background-color:      es-color('clario-mono', 'gray-4') !default;
    $es-button-default-hover-text-color:            es-color('clario-mono', 'gray-12') !default;
    $es-button-default-hover-border-color:          es-color('clario-mono', 'gray-8') !default;
    /// focus state
    $es-button-default-focus-border-color:          es-color("clario-mono", "gray-12");
    /// active state
    $es-button-default-active-background-color:     es-color("clario-mono", "gray-5");
    /// disabled state
    $es-button-default-disabled-background-color:   es-color("clario-mono", "gray-4", .5) !default;
    $es-button-default-disabled-text-color:         es-color("clario-mono", "gray-10") !default; //not used
    $es-button-default-disabled-border-color:       es-color("clario-mono", "gray-7") !default; //not used
    // default button dark background
    $es-button-default-dark-background-color:       transparent;
    $es-button-default-dark-text-color:             es-color("clario-mono", "white") !default;
    $es-button-default-dark-border-color:           es-color("clario-mono", "white") !default;
  
  // primary button colors
    /// enabled state
    $es-button-primary-background-color:            es-color("clario-teal", "teal-11") !default;
    $es-button-primary-border-color:                es-color('clario-teal', 'teal-11') !default;
    $es-button-primary-text-color:                  es-color('clario-mono', 'white') !default;
    $es-button-primary-box-shadow-color:            es-color("clario-mono", "black", .2) !default;
    /// hover state
    $es-button-primary-hover-background-color:      es-color('clario-teal', 'teal-10') !default;
    $es-button-primary-hover-box-shadow-color:      es-color('clario-mono', 'gray-10') !default;
    /// focus state
    $es-button-primary-focus-border-color:          es-color("clario-gray", "gray-12");
    /// active state
    $es-button-primary-active-background-color:     es-color("clario-teal", "teal-9");
    /// disabled state
    $es-button-primary-disabled-background-color:   es-color("clario-teal", "teal-7", .5) !default;
    $es-button-primary-disabled-border-color:       es-color("clario-teal", "teal-7") !default; //not used
    $es-button-primary-disabled-text-color:         es-color("clario-mono", "white") !default; //not used
    $es-button-primary-disabled-box-shadow-color:   es-color("clario-mono", "black", .2) !default; //not used
    // primary button dark background
    $es-button-primary-dark-background-color:       es-color("clario-mono", "white") !default;
    $es-button-pimary-dark-border-color:            es-color("clario-mono", "white") !default;
    $es-button-primary-dark-text-color:             es-color("clario-mono", "gray-12") !default;
  
  // cancel button colors
    /// enabled state
    $es-button-danger-background-color:             es-color("clario-red", "red-10");
    $es-button-danger-border-color:                 es-color("clario-red", "red-10");
    $es-button-danger-box-shadow-color:             es-color("mono", "black", .2);
    $es-button-danger-text-color:                   es-color("mono", "white");
    /// hover state
    $es-button-danger-hover-background-color:       es-color("clario-red", "red-11");
    $es-button-danger-hover-border-color:           es-color("clario-red", "red-11");
    /// focus state
    $es-button-danger-focus-border-color:           es-color("clario-mono", "gray-12");
    /// active state
    $es-button-danger-active-background-color:      es-color("clario-red", "red-9");
    /// disabled state
    $es-button-danger-disabled-background-color:    es-color("clario-red", "red-10", 0.5);
    $es-button-danger-disabled-border-color:        es-color("mono", "oslo-grey"); //not used
    $es-button-danger-disabled-text-color:          es-color("mono", "alto"); //not used
    $es-button-danger-disabled-box-shadow-color:    es-color("mono", "black", .2); //not used

  // secondary button colors
    /// enabled state
    $es-button-secondary-background-color:             es-color("clario-mono", "gray-3");
    $es-button-secondary-border-color:                 es-color("clario-mono", "gray-3");
    $es-button-secondary-box-shadow-color:             es-color("mono", "black", .2);
    $es-button-secondary-text-color:                   es-color("clario-mono", "gray-12");
    /// hover state
    $es-button-secondary-hover-background-color:       es-color("clario-mono", "gray-4");
    $es-button-secondary-hover-border-color:           es-color("clario-mono", "gray-4");
    /// focus state
    $es-button-secondary-focus-border-color:           es-color("clario-mono", "gray-12");
    /// active state
    $es-button-secondary-active-background-color:      es-color("clario-mono", "gray-5");
    /// disabled state
    $es-button-secondary-disabled-background-color:    es-color("clario-mono", "gray-9", 0.5);


.es-button {
  
  border: 1px solid;

  // Reset for round buttons on Mac/Ios
  // @TODO: This should be separated into a reset file (with all the other resets used in the SG)
  border-radius: $es-button-border-radius;

  cursor: pointer;
  display: inline-block;
  line-height: $es-button-line-height;
  padding: es-to-rem($es-space-inset-squish-default);
  text-align: center;
  
  // disabled state
  &:disabled,
  &[disabled=disabled] {
    cursor: not-allowed;
  }

  .es-icon {
    display: inline-block;
    font-size: $es-button-icon-size;
    // harmonize icons and text vertically
    top: -(es-to-rem(2px));
    position: relative;
    color: inherit;
  }

  // space between:
  //  - icon - span and vice versa
  //  - icon - label and vice versa
  .es-icon + span,
  .es-icon + label,
  span + .es-icon,
  label + .es-icon  {
    margin: es-to-rem($es-space-gutter-left-small);
  }
  

  // colors for default button
  & {
    background-color:       $es-button-default-background-color;
    border-color:           $es-button-default-border-color;
    color:                  $es-button-default-text-color;
    box-shadow:             0px 1px 2px 0px $es-button-default-box-shadow-color;

    &.-es-contrast {
      background-color:     $es-button-default-dark-background-color;
      border-color:         $es-button-default-dark-border-color;
      color:                $es-button-default-dark-text-color;

      &:hover {
        color:              $es-button-default-dark-text-color;
        background-color:   es-color("mono", "white", .1);
      }
    }
    
    &:hover {
      background-color:     $es-button-default-hover-background-color;
      border-color:         $es-button-default-hover-border-color;
      color:                $es-button-default-hover-text-color;
    }

    &:focus {
        border-color:       $es-button-default-focus-border-color;
    }

    &:active {
        background-color:   $es-button-default-active-background-color;
    }

    &:disabled,
    &[disabled=disabled] {
      background-color:     $es-button-default-disabled-background-color;
      border-color:         transparent;
      box-shadow:           none;
      color:                $es-button-default-disabled-text-color;
    }
  }

  // colors for primary button
  &.-es-primary {
    background-color:       $es-button-primary-background-color;
    border-color:           $es-button-primary-border-color;
    box-shadow:             0px 1px 2px 0px $es-button-primary-box-shadow-color;
    color:                  $es-button-primary-text-color;

    &:hover{
      background-color:     $es-button-primary-hover-background-color;
      border-color:         $es-button-primary-hover-background-color;
      box-shadow:           0px 1px 2px 0px $es-button-primary-hover-box-shadow-color;
    }

    &:focus {
      border-color:         $es-button-primary-focus-border-color;
      box-shadow:           0px 1px 2px 0px $es-button-primary-hover-box-shadow-color;
    }

    &:active {
        background-color:   $es-button-primary-active-background-color;
        border-color:       $es-button-primary-active-background-color;
        color:              $es-button-primary-text-color;
    }

    &:disabled,
    &[disabled=disabled] {
      background-color:     $es-button-primary-disabled-background-color;
      border-color:         transparent;
      box-shadow:           none;
      color:                $es-button-primary-text-color;
    }

    &.-es-contrast {
      background-color:     $es-button-primary-dark-background-color;
      border-color:         $es-button-pimary-dark-border-color;
      color:                $es-button-primary-dark-text-color;

      &:hover {
        color:              $es-button-default-text-color;
        background-color:   es-color("mono", "white", .9);
        border-color:       transparent;
        box-shadow:         0px 1px 2px 0px $es-button-primary-hover-box-shadow-color;
      }
    }
  }

  // colors for cancel button
  &.-es-danger {
    background-color:       $es-button-danger-background-color;
    border-color:           $es-button-danger-border-color;
    box-shadow:             0px 1px 2px 0px $es-button-danger-box-shadow-color;
    color:                  $es-button-danger-text-color;

    &:hover {
      background-color:     $es-button-danger-hover-background-color;
      border-color:         $es-button-danger-hover-border-color;
    }

    &:focus {
      border-color:         $es-button-danger-focus-border-color;
    }

    &:active {
      background-color:     $es-button-danger-active-background-color;
      border-color:         $es-button-danger-active-background-color;
    }

    &:disabled,
    &[disabled=disabled] {
      background-color:     $es-button-danger-disabled-background-color;
      border-color:         transparent;
      box-shadow:           none;
      color:                $es-button-danger-text-color;
    }
  }

  // colors for Secondary button
  &.-es-secondary {
    background-color:       $es-button-secondary-background-color;
    border-color:           $es-button-secondary-border-color;
    box-shadow:             0px 1px 2px 0px $es-button-secondary-box-shadow-color;
    color:                  $es-button-secondary-text-color;

    &:hover {
      background-color:     $es-button-secondary-hover-background-color;
      border-color:         $es-button-secondary-hover-border-color;
    }

    &:focus {
      border-color:         $es-button-secondary-focus-border-color;
    }

    &:active {
      background-color:     $es-button-secondary-active-background-color;
      border-color:         $es-button-secondary-active-background-color;
    }

    &:disabled,
    &[disabled=disabled] {
      background-color:     $es-button-secondary-disabled-background-color;
      border-color:         transparent;
      box-shadow:           none;
      color:                $es-button-secondary-text-color;
    }
  }

  &.-es-small {
    padding:              es-to-rem($es-space-inset-squish-small);
  }
}

a.es-button{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}