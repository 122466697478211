//// ----------------------------------------------------------------------
///
/// Gateway template
///
/// @group templates
/// @module sass/templates/gateway
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

.es-gateway {
  // Gateway template Variables
  $es-gateway-header-logo-height: 3 * $es-base-unit;

  background: $es-background-light-color;
  @extend %es-page;

  .es-gateway-header {
    padding: es-to-rem($es-space-inset-xlarge);
    width: 100%;
    & .es-logo {
      display: table;
      line-height: es-to-rem($es-gateway-header-logo-height);
      margin: 0 auto;
      & img {
        height: es-to-rem($es-gateway-header-logo-height);
        vertical-align: middle;
      }
    }
  }

  .es-gateway-body {
    @include es-clearfix;
    @extend %-es-page-body-fixed-width;
    @extend %es-container-inset-modifiers;
    margin: 0 auto;

    &.-es-small {
      @extend %-es-page-body-fixed-width-small;
    }
    h1 {
      text-align: center;
    }
    // Change alignment for icons in headings
    h1,
    h2,
    h3,
    h4 {
      .es-icon {
        vertical-align: text-bottom;
      }
    }
  }

  .es-gateway-footer {
    @extend %es-page-footer;
    .es-page-footer-layout {
      background-color: transparent;
      li+li:before {
        color: $es-anchor-color;
      }
    }
  }
}