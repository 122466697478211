//// ----------------------------------------------------------------------
///
/// File item, for file upload forms
///
/// @group partials
/// @module sass/molecules/file-item
//// ----------------------------------------------------------------------
.es-file-item {
    @extend %es-container-inset-modifiers;

    background-color: $es-background-color;
    border: 1px solid $es-border-color;
    color: $es-control-color;

    .es-icon {
        font-size: 1.3em;
    }
    .es-spinner {
        height: 1.3em;
        width: 1.3em;
    }
    .es-actions {
        display: table-cell;
        a {
            color: $es-icon-color;
        }
        white-space: nowrap;
        & > a:not(:last-of-type) {
            margin: es-to-rem($es-space-gutter-right-default);
        }
        .-es-warning {
            color: $es-font-warning-color;
        }
    }
    .es-file-name {
        display: table-cell;
        font-weight: 500;
        width: 100%;
        word-break: break-all;
    }
    &.-es-invalid {
        @extend %es-control-invalid;
    }
}