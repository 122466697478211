//// ----------------------------------------------------------------------
///
/// Dashboard widget
///
/// @group containers
/// @module sass/components/containers/widget
///
//// ----------------------------------------------------------------------

.es-widget {
  // Widget variables
  $es-widget-border-size: 1px;
  $es-widget-nubbin-size: 10px;
  $es-widget-button-close-color: $es-font-color;
  $es-widget-button-close-hover-color: $es-anchor-hover-color;
  $es-widget-close-icon-size: 14px;
  $es-widget-header-icons-size: $es-icon-size-large;

  border: $es-widget-border-size solid $es-border-light-color;

  .es-widget-header {
    background: $es-background-color;
    border-bottom: $es-widget-border-size solid $es-border-light-color;
    color: $es-font-bright-color;
    display: flex;
    justify-content: space-between;
    padding: es-to-rem($es-space-inset-squish-default);
    position: relative;
    width: 100%;

    // IE specifc table-cell layout for flexbox issues in IE, remove when IE is no longer supported
    @include IE-10-11-only() {
      display: table;
    }   


    // Title
    h1 {
      @extend %es-typo-settings-h3;
      color: $es-font-color;
      // font-size: es-to-rem($es-h3-font-size);
      // line-height: es-to-rem($es-h3-line-height);
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include IE-10-11-only() {
        display: table-cell;
        overflow: visible;
        text-overflow: unset;
        width: 100%;
        white-space: normal;
        word-break: break-all;
      }
    }

    // close button
    button[data-es-expand-collapse] {
      @extend %es-default-button-reset;
      bottom: 0;
      color: $es-widget-button-close-color;
      font-size: es-to-rem($es-widget-close-icon-size);
      left: 0;
      margin: es-to-rem($es-space-inset-small);
      padding: es-to-rem($es-space-inset-small);
      position: absolute;
      top: 0;
      vertical-align: middle;
      &:hover {
        color: $es-widget-button-close-hover-color;
      }
    }

    // styles for buttons in header
    .es-widget-action-bar {
      display: block;
      padding-left: es-to-rem($es-space-small);
      height: es-to-rem($es-h3-line-height);

      @include IE-10-11-only() {
        display: table-cell;
      }

      & > a {
        .es-icon {
          font-size: es-to-rem($es-widget-header-icons-size);
          line-height: es-to-rem($es-h3-line-height);
        }
      }

      .es-dropdown > button {
        line-height: es-to-rem($es-h3-line-height);
        margin: 0;
        padding: 0;
      }

      // assure space between close button and adjacent element
      & > *:not(:last-child) {
        margin: es-to-rem($es-space-gutter-right-small);
      }
    }
  }

  // closeable widget, reserve space for close icon
  &.-es-collapsible {
    .es-widget-header {
      padding-left: es-to-rem($es-space-default + $es-icon-size + $es-space-default);
    }
  }

  // content styles
  .es-widget-body {
    // Inset spacing
    @extend %es-container-inset-modifiers;

    background-color: $es-background-color;
  }

  &.-es-collapsed {
    border-bottom: none;
    .es-widget-body {
      display: none;
    }
    .es-widget-header:before,
    .es-widget-header:after {
      content:none;
    }
  }


}
