//// ----------------------------------------------------------------------
///
/// Form-control related mixins, functions or placeholder
///
/// @group modules
/// @module sass/modules/components/form-controls
///
/// @require placeholder es-control-basic
/// @require placeholder es-control-focus
/// @require placeholder es-control-hover
/// @require placeholder es-control-invalid
/// @require mixin es-color
/// @require mixin es-em-calc
//// ----------------------------------------------------------------------


//Mixins for basic form controls


%es-checkbox-radio {
  $checkbox-size: $es-checkbox-size - 2px; // subtract border //@TODO: needed at all?

  display: inline-block;
//  position: relative;
  input {
    //don't use display: none or visibility: hidden. Otherwise the checkbox will not be focusable
    //and also not navigable using TAB
    clip: rect(0,0,0,0);
    appearance: none;
    position: absolute;
  }
  input + label {
    display: inline-block;
    height: es-to-rem($es-checkbox-size);
    line-height: es-to-rem($es-checkbox-size);

    padding: 0 0 0 es-to-rem($es-checkbox-size + $es-space-gutter-small);

    // correct padding when label is empty (just checkbox/radio)
    &:empty {
      padding: 0 0 0 es-to-rem($es-checkbox-size);
      // This prevents that the height of the checkbox/radio gets corrupted, when empty.
      vertical-align: middle;
    }

    position: relative;
    &:before {
      @extend %es-control-basic;
      content: '';
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      width: es-to-rem($es-checkbox-size);
      &:hover {
        @extend %es-control-hover;
      }
    }
    &:after {
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      top: 0; 
      text-align: center;
      position: absolute;
      width: es-to-rem($es-checkbox-size);
    }
  }
  input:focus + label:before{
    @extend %es-control-focus;
  }
}

%es-checkbox-radio-padding {
  //$checkbox-vertical-padding: es-to-rem(($es-control-height - $es-checkbox-size) * .5); // subtract border

  // fill space up to result in "control height"
  padding: es-to-rem($es-checkbox-vertical-padding) 0;
}

%es-control-switch-padding-fill {
  // fill space up to result in "control height"
  padding: es-to-rem($es-switch-vertical-padding) 0;
}


%es-input {
  @extend %es-control;
  padding: es-to-rem($es-space-inset-squish-default);
}
