//// ----------------------------------------------------------------------
///
/// Collapsible panel
///
/// @group containers
/// @module sass/components/containers/collapsible-panel
///
/// @require function es-color
/// @require function es-get-icon
/// @require mixin es-em-calc
/// @require mixin es-overlay
//// ----------------------------------------------------------------------

.es-collapsible-panel {

  $es-collapsible-panel-background-color: $es-background-color;
  $es-collapsible-panel-border-color: $es-border-light-color;
  $es-collapsible-panel-button-close-default-color: $es-anchor-color;
  $es-collapsible-panel-button-close-focus-color: $es-anchor-focus-color;
  $es-collapsible-panel-button-close-hover-color: $es-anchor-hover-color;
  $es-collapsible-panel-header-icons-size: 24px;
  
  background: $es-collapsible-panel-background-color;
  border: 1px solid $es-collapsible-panel-border-color;
  display: block;
  margin: 0;

  .es-collapsible-panel-header {
    // for action bar
    @include es-clearfix;
    
    overflow-wrap: break-word;
    padding: es-to-rem($es-space-inset-squish-large);
    padding-left: es-to-rem($es-space-large + $es-icon-size + $es-space-default);
    position: relative;
    word-wrap: break-word; //IE

    // Widths modifiers for children of panel header
    @include es-layout-create-width-modifiers(12, "-es-width-");
    
    // toggle button
    button[data-toggle] {
      @extend %es-default-button-reset;
      color: $es-collapsible-panel-button-close-default-color;
      left: 0;
      margin: es-to-rem($es-space-inset-default);
      padding: es-to-rem($es-space-inset-squish-default);
      position: absolute;
      top: 3px;
      vertical-align: middle;
      
      @extend .es-icon;
      &:before {
        @include es-control-icon(chevron-right, $es-icons-font-size-base);
        transition: transform .2s;
      }
      &:hover {
        color: $es-collapsible-panel-button-close-hover-color;
      }
      &:focus {
        color: $es-collapsible-panel-button-close-focus-color;
        outline: none;
      }
      &:disabled {
        color: $es-disabled-color;
      }
    }


    // title
    h1, 
    h2, 
    h3 {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      width: auto;
      &[data-toggle] {
        cursor: pointer;        
      }
    }
    h3 {
      font-size: 1em;
      font-weight: bold;
    }

    // actionbar
    .es-actionbar {
      margin: es-to-rem($es-space-gutter-right-small);

      .es-checkbox {
        // Correct for half control height
        padding-bottom: $es-checkbox-vertical-padding;
      }
    }
    
  }

  .es-collapsible-panel-body {
    @extend %es-container-inset-modifiers;
    display: none;
  }

  &.-es-open {
    .es-collapsible-panel-header {
      // switch toggle button to chevron down
      button[data-toggle] {
        &:before {
          transform: rotate(90deg);
        }
      }
    }

    .es-collapsible-panel-body {
      display: block;
    }
  }
}
