//// ----------------------------------------------------------------------
///
/// Message Banner
///
/// @group components
/// @module sass/partials/components/alerts/message-banner
///
//// ----------------------------------------------------------------------

// Variables for message banner
$es-message-banner-padding: $es-space-small;

.es-message-banner {
  // local Variables for message banner
  $es-message-banner-mobile-padding: $es-space-small;
  $es-message-banner-default-color: es-color("clario-mono", "gray-12");

  color: $es-font-bright-color;
  background-color: $es-message-banner-default-color;
  padding: es-to-rem($es-message-banner-mobile-padding);
  text-align: center;

  & > h1 {
    font-size: es-to-rem($es-h4-font-size);
    line-height: es-to-rem($es-line-height);
    margin: 0;
  }

  & .es-icon {
    font-size: es-to-rem($es-icon-size-large);
  }

  &.-es-warning {
    color: $es-font-bright-color;
    background-color: $es-danger-color;
  }
  &.-es-info {
    color: $es-font-bright-color;
    background-color: $es-info-color;
  }
}

@include es-viewport-range(mobile-large-up) {
  .es-message-banner {
    padding: es-to-rem($es-message-banner-padding);

    // & > h1 {
      //font-size: es-to-rem($es-h4-font-size);
    // }
  }
}

