//// ----------------------------------------------------------------------
///
/// Modules for page-styles (templates)
///
/// @group modules
/// @module sass/modules/templates/page
///
//// ----------------------------------------------------------------------

// General page extension
%es-page {
  min-height: 100vh;
  position: relative;
}

// Fixed width extension
%-es-page-body-fixed-width {
  max-width: $es-page-main-medium-max-width;
}

%-es-page-body-fixed-width-small {
  max-width: $es-page-main-small-max-width;
}