//// ----------------------------------------------------------------------
///
/// Section header and footer
///
/// @group containers
/// @module sass/components/containers/section

//// ----------------------------------------------------------------------

section .es-header{
  p{
    font-weight: bold;
  }
  h1{
    font-size: es-em-calc($es-h2-font-size);
    line-height: es-em-calc($es-h2-line-height, $es-h2-font-size);
  }
  /* sub-headings */
  h1 + p{
    font-family: $es-font-headings;
    font-weight: $es-font-weight-headings;
    font-size: es-em-calc($es-h3-font-size);
  }
  h1 + p + p{
    font-size: 1em;
  }
}
/*@deprecated*/
.es-header.-es-bright {
  h1{
    color: $es-font-alt-bright-color;
  }
  h2{
    color: $es-font-bright-color;
  }
}

/**
section footer
**/
.es-footer{
  @include es-contains-inline-nav;
  nav{
    font-family: $es-font-primary;
    font-size: es-em-calc(13);
  }
  &.-es-bright nav a{
    color: $es-font-bright-color;
  }
}



