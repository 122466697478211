//// ----------------------------------------------------------------------
///
/// Card item, for informational purposes
///
/// @group containers
/// @module sass/components/containers/card
/// @mixin es-to-rem();
//// ----------------------------------------------------------------------

.es-card {
  $es-card-text-default-color: $es-font-color;
  $es-card-header-icons-size: 17px;
  $es-card-header-actionbar-correction: -10px;

  @extend %es-container-background-with-border;
  width: 100%;

  .es-card-header {
    // border-bottom: 1px solid $es-border-highlight-color;
    padding: es-to-rem($es-space-inset-squish-large);
    h1, h2, h3, h4 {
      color: $es-font-color;
      margin: 0;
    }

    // optional action bar in header
    .es-actionbar {
      display: block;
      padding-left: es-to-rem($es-space-small);
      height: es-to-rem($es-h3-line-height);
      margin-right: es-to-rem($es-card-header-actionbar-correction);

      @include IE-10-11-only() {
        display: table-cell;
      }

      & > a {
        .es-icon {
          font-size: es-to-rem($es-card-header-icons-size);
          line-height: es-to-rem($es-h3-line-height);
        }
      }

      .es-dropdown > button {
        line-height: es-to-rem($es-h3-line-height);
        margin: 0;
        padding: 0;
      }

      // assure space between close button and adjacent element
      & > *:not(:last-child) {
        margin: es-to-rem($es-space-gutter-right-small);
      }
    }       
  }
  .es-card-footer,
  .es-card-body {
    @extend %es-container-inset-squish-modifiers;
    // set default color
    color: $es-card-text-default-color;
    
    //Overflow handling
    @include es-overflow-wrap;
  }
}

// Pattern Data card
.es-card.-es-data,
.es-card.-es-data-medium {
  table {
    border-spacing: 0;
    padding: 0;
    table-layout: fixed;
    text-align: left;
    width: 100%;
  }
  td {
    padding-left: es-to-rem($es-space-default);
    vertical-align: bottom;

    //Overflow handling
    @include es-overflow-wrap;
  }
  th {
    font-weight: normal;
    vertical-align: top;
    width: 35%;
  }
}

// Medium sized Data card
.es-card.-es-data-medium {
  max-width: es-to-rem($es-width-medium);
}

// extension when card is used in es-layout-flex-list
.es-layout-flex-list {
  .es-card {
    .es-card-header {
      @include es-flex-size-static;

      display: flex;
      align-items: center;
  
      position: relative;

      // adjusting heading
      h1, h2, h3, h4 {
        @include es-flex-size-scalable;

        @include es-overflow-text-ellipsis;
      }
    }

    .es-card-footer,
    .es-card-body {
      @include es-flex-size-scalable;
      display: flex;
      overflow: hidden;
    }
  }
}


