//// ----------------------------------------------------------------------
///
/// Bullet List Module
///
/// Helpers/Extensions/Mixings to be used with bullet/legend lists
///
/// @group partials
/// @module sass/modules/components/bullet-list
///
//// ----------------------------------------------------------------------


// Colors for bullets
$es-bullet-item-success-color:    es-color('clario-teal', 'teal-9');
$es-bullet-item-error-color:      es-color('clario-red', 'red-9');
$es-bullet-item-warning-color:    es-color('clario-yellow', 'yellow-10');

// icon sizes for bullets
$es-bullet-item-icon-size-small:   10px;
$es-bullet-item-icon-size-large:   17px;

%es-bullet-list {
  @extend %es-reset-ul;

  > li {
    display: flex;
    flex-direction: row;
    
    &:not(:last-child) {
      margin-bottom: es-to-rem($es-space-small);
    }

    .es-icon,
    .-es-bullet,
    .es-bullet {
      // restore default line-height
      line-height: es-to-rem($es-line-height);
    }

    .-es-bullet,
    .es-bullet {
      width: es-to-rem($es-space-large + $es-space-default);
      flex-shrink: 0;
    }

    // to be used with text spans
    .es-bullet {
      font-weight: bold;
    }
}

  // bullet list using small icons
  &.-es-bullet-small {
    li {
      .-es-bullet,
      .es-bullet {
        font-size: es-to-rem($es-bullet-item-icon-size-small);
      }
    }
  }
  
  // bullet list using large icons
  &.-es-bullet-large {
    li {
      .-es-bullet,
      .es-bullet {
        font-size: es-to-rem($es-bullet-item-icon-size-large);
      }
    }
  }
}

%es-bullet-default-color-modifiers {
  // default colors for bullets
  & .-es-success,
  & .-es-ok {
    color: $es-bullet-item-success-color;
  }

  & .-es-error,
  & .-es-failed { 
    color: $es-bullet-item-error-color;
  }

  & .-es-warning { 
    color: $es-bullet-item-warning-color;
  }
}