//// ----------------------------------------------------------------------
///
/// Wizard steps, a progress indicator
///
/// @group components
/// @module sass/components/indicators/wizard-steps

//// ----------------------------------------------------------------------

// Variables for wizard steps
$es-steps-color-inactive: es-color('clario-mono','gray-7');
$es-steps-color-active: es-color('clario','purple-d');
$es-steps-color-visited: es-color('clario-teal','teal-9');
$es-steps-color-rule: es-color('clario-mono','gray-7');
$es-steps-color-text: es-color('clario-mono','gray-11');
$es-steps-color-text-active: es-color('clario-mono','gray-12');

$es-steps-size: 16px;
$es-steps-size-mobile: 13px;
$es-steps-outline-size: 1px;
$es-steps-rule-weight: es-to-rem(2px);
$es-steps-padding: $es-steps-size * .4;

.es-steps {
  ol {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }
  ol + ol {
    margin-top: es-to-rem($es-space-default);
  }
  li {
    position: relative;

    & .es-step {
      background-color: $es-steps-color-inactive;
      border-radius: 999px;
      color: $es-font-bright-color;
      display: inline-block;
      font-size: es-to-rem($es-steps-size-mobile);
      height: es-to-rem($es-steps-size);
      // outline: 1px solid $es-steps-color-inactive;
      position: relative;
      text-align: center;
      width: es-to-rem($es-steps-size);
    }
    label,
    a {
      color: $es-steps-color-text;
      display: inline-block;
      margin: es-to-rem($es-space-default) 0 0 es-to-rem($es-space-default);
    }
    .es-step.-es-active {
      background-color: $es-steps-color-active;
      outline: 1px solid $es-steps-color-active;
    }
    .-es-active + label,
    .-es-active + a {
      color: $es-steps-color-text-active;
      font-weight:600;
    }
    .es-step.-es-visited {
      background-color: $es-steps-color-visited;
      outline: 1px solid $es-steps-color-visited;
    }
    .-es-visited + label,
    .-es-visited + a {
      color: $es-steps-color-text;
    }

  }
  li:before {
    background-color: $es-steps-color-rule;
    content: "";
    display: block;
    position: absolute;
  }
}

.es-steps.-es-substeps {
  li {
    .es-step {
      padding: $es-steps-padding 0 $es-steps-padding 0;
      text-align: center;
      width: es-to-rem($es-steps-size);
    }
  }
}

@include es-viewport-range('mobile-only') {
  .es-steps {
    ol {
      padding: es-to-rem($es-steps-outline-size);

      li:not(:last-child) {
        padding-bottom: 1em;
      }
    }
    li:before {
      height: 100%;
      left: es-to-rem($es-steps-size-mobile);
      top: 0;
      width: $es-steps-rule-weight;
    }
    li:first-child:before {
      bottom: 0;
      height: 50%;
      top: auto;
    }
    li:last-child:before {
      height: 50%;
    }
  }
}

@include es-viewport-range('mobile-large-up') {
  .es-steps {
    text-align: center;
    ol {
      @include es-clearfix;
      // Flexbox for removing whitespaces
      display: flex;
      justify-content: center;
      width: 100%;
    }
    li {
      display: inline-block;
      //adjusting trailing whitespace with negative margin, should only be backup solution
      //margin: 0 -.2em;
      // Adjust outline spacing
      padding-top: es-to-rem($es-steps-outline-size);
      vertical-align: top;
      width: 20%;

      .es-step {
        font-size: es-to-rem($es-steps-size);
        height: es-to-rem($es-steps-size * 2);
        padding: es-to-rem($es-steps-padding);
        width: es-to-rem($es-steps-size * 2);
      }
      label,
      a {
        display: block;
        margin: es-to-rem($es-space-default) 0 0 0;
      }
    }
    li:before {
      height: $es-steps-rule-weight;
      left: 0;
      margin: es-to-rem($es-steps-outline-size) 0 0 0;
      top: es-to-rem($es-steps-size);
      vertical-align: middle;
      width: 100%;
    }
    li:first-child:before {
      left: auto;
      right: 0;
    }
    li:first-child:before,
    li:last-child:before {
      width: 50%;
    }
  }
}

