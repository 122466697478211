//// ----------------------------------------------------------------------
///
/// Sidebar component
///
/// @group navigation
///
/// @component components/navigation/nav-item
/// @config components/sidebar
/// @modules core/parts
///
//// ----------------------------------------------------------------------

@import "../../_configuration/components/nav-item";
@import "../../_configuration/components/sidebar";
@import "../../_modules/core/transition";

.es-sidebar {
  background-color: $es-sidebar-background-color;
  color: $es-nav-item-color;
  font-family: $es-font-primary;
  overflow: visible;
  width: $es-sidebar-width-mobile;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .es-nav-items {
    background-color: $es-nav-item-background-color;
    //limit max height and set overflow to auto to enable scrolling when sidebar content exceeds width
    max-height: calc(100vh - #{$es-page-header-height});
    overflow: auto;

    width: $es-sidebar-width-mobile;
  }

  .es-toggle,
  .es-nav-items {
    z-index: $es-sidebar-z-index-mobile;
  }

  /*
  Toggle button
  ----------------------------------------*/
  .es-toggle {
    @include es-nav-item;
    @include es-nav-item-height($es-nav-item-height);
    background-color: $es-sidebar-toggle-background-color;
    a, button {
      color: $es-nav-item-color;
      padding: 0 $es-gutter;
    }
  }

  .es-toggle .es-icon:before {
    content: es-get-icon('options');
  }
}

.es-sidebar.-es-closed {
  overflow: hidden;
  /*
  Nested Items
  ----------------------------------------*/
  //always show a chevron down icon
  .es-nav-item.-es-open > a:before,
  .es-nav-item.-es-open > button:before,
    /*css-collapsible variant*/
  .es-nav-item.-es-collapsible > a:focus:before,
  .es-nav-item.-es-collapsible > button:focus:before {
    content: es-get-icon('chevron-down');
  }
}

/*
Sidebar Opened
----------------------------------------
----------------------------------------*/

.es-sidebar.-es-open {
  .es-toggle {
    a, button {
      text-align: right;
    }
    .es-icon:before {
      content: es-get-icon('chevron-left');
    }
  }
}

/*
Tablet
----------------------------------------
----------------------------------------*/
@include es-viewport-range(mobile-large-up) {

  .es-sidebar {
    width: $es-sidebar-width-desktop;
    .es-nav-items {
      display: block;
      //limit max height and set overflow to auto to enable scrolling when sidebar content exceeds width
      max-height: calc(100vh - #{es-to-rem($es-page-header-height + $es-nav-item-height)});
      width: 100%;
    }

    .es-toggle,
    .es-nav-item {
      a, button {
        padding-left: es-to-rem(($es-sidebar-width-closed - $es-nav-item-icon-size) * .5);
      }
    }

    .es-nav-item.-es-active {
      a, button {
          padding-left: es-to-rem(calc($es-gutter - 3px)); // alignment adjustment for inline start border
      }
    }

    //transition for opening/closing the sidebar
    transition: min-width $es-nav-item-transition-duration;

    /* Sidebar Open
    ----------------------------------------*/

    &.-es-open {
      @include es-transition-expand-horizontal($es-sidebar-width-tablet);
      min-width: es-to-rem($es-sidebar-width-tablet);
    }

    /* Sidebar Closed
    ----------------------------------------*/

    &.-es-closed {
      @include es-transition-collapse-horizontal($es-sidebar-width-closed);
      min-width: es-to-rem($es-sidebar-width-closed);

      //override mobile styles
      overflow: auto;

      label {
        display: none;
      }

      /*
        chevron icons
      */
      .es-nav-item.-es-open > a:before,
      .es-nav-item.-es-open > button:before,
      .es-nav-item.-es-closed > a:before,
      .es-nav-item.-es-closed > button:before,
      .es-nav-item.-es-collapsible > a:before,
      .es-nav-item.-es-collapsible > button:before {
        right: es-to-rem($es-space-small);
        font-size: es-to-rem($es-nav-item-chevron-size-small);
      }

      //never show nested items when the sidebar is closed
      .es-nav-item ul {
        display: none;
      }
    }
  }
}

/* Desktop
----------------------------------------
----------------------------------------*/

@include es-viewport-range(desktop-up) {

  .es-sidebar {
    //overflow needs to be visible in desktop because nested items are supposed to fly outside on the right.
    overflow: visible;

    //subnav
    .es-nav-item ul {
      position: absolute;
      left: es-to-rem($es-sidebar-width-desktop);
      margin-top: $es-nav-item-height * -1;
      border: 1px solid $es-border-light-color;
      border-inline-start: none; 
      li {
        position: relative;
      }
      a, button {
        padding-left: es-to-rem($es-gutter);
      }
    }

    .es-nav-item.-es-active {
      a, button {
        padding-inline-start: es-to-rem(calc($es-gutter - 3px)); // alignment adjustment for inline start border
      }
    }

    //expand on hover
    .es-nav-items li:hover > ul {
      display: block;
    }

    //align flyout to bottom
    .-es-flyout-left-bottom,
    .-es-flyout-left-bottom ul {
      bottom: 0;
    }

    .es-nav-items {
      //overflow needs to be visible in desktop because nested items are supposed to fly outside on the right.
      overflow: visible;
    }

    .es-nav-item li {
      min-height: es-to-rem($es-nav-item-height);
    }

    &.-es-closed {
      //override tablet styles
      overflow: visible;
      .es-nav-item ul {
        //override tablet styles
        display: none;
        left: es-to-rem($es-sidebar-width-closed);
      }
    }
  }

  /*
  Nested Items
  ----------------------------------------*/

  .es-sidebar {
    /*always show chevron-right in desktop*/
    .es-nav-item.-es-open > a:before,
    .es-nav-item.-es-open > button:before,
    .es-nav-item.-es-closed > a:before,
    .es-nav-item.-es-closed > button:before,
    .es-nav-item.-es-collapsible > a:before,
    .es-nav-item.-es-collapsible > button:before {
      //color: es-color("blue", "light-blue") !important;
      content: es-get-icon('chevron-right') !important;
    }
  }

}