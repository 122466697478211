//// ----------------------------------------------------------------------
///
/// Loading indicator component
///
/// @group indicators
/// @module sass/components/indicators/loading
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

$es-loading-icon-border-width-small: 2px;
$es-loading-icon-size-small:  $es-icon-size !default;

$es-loading-icon-border-width-medium: 6px;
$es-loading-icon-size-medium: 50px;

$es-loading-icon-border-width-large: 11px;
$es-loading-icon-size-large: 90px;

$es-loading-icon-animation-duration: .8s !default;


// loading indicator wrapper/container configuration

$es-loading-indicator-backdrop-color: es-color('clario-mono', 'gray-9', .5) !default;
$es-loading-indicator-backdrop-contrast-color: es-color('mono', 'white', .5) !default;
// loading indicator icon
.es-loading-icon {
  color: inherit;
  display: inline-block;
  height: es-to-rem($es-loading-icon-size-small);
  //harmonize icons and text
  margin-top: -(es-to-rem(2px));
  position: relative;
  vertical-align: middle;
  width: es-to-rem($es-loading-icon-size-small);
    text-rendering: geometricPrecision;

  // loading indicator shape
  &:after {
    animation: loading-icon-rotate $es-loading-icon-animation-duration infinite linear;
    border-radius: 50%;
    border: es-to-rem($es-loading-icon-border-width-small) solid;
    border-right-color: transparent;
    bottom: 0; 
    color: inherit;
    content: '';
    left: 0; 
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; 
  }
}

// spacing between loading indicator and text span (and vice versa)
span + .es-loading-icon,
.es-loading-icon + span {
  padding: es-to-rem($es-space-gutter-left-small);
}


// loading indicator wrapper element
.es-loading-indicator {
    display: none;
    height: 100%;
    min-height: $es-loading-icon-size-medium;
    min-width: $es-loading-icon-size-medium;
    position: relative;
    width: 100%;

  // medium size loading indicator when in wrapper element
  & .es-loading-icon {
    position: absolute;
    height: es-to-rem($es-loading-icon-size-medium);
    left: 50%;
    margin: 0; // remove indicator and text harmonization
    top: 50%;
    transform: translate(-50%, -50%);
    width: es-to-rem($es-loading-icon-size-medium);

    // by default the loading indicator icon is shown in medium size
    &:after {
        border-width: es-to-rem($es-loading-icon-border-width-medium);
    }
  }  

  // modifier for fullscreen usage of loading indicator
  &.-es-fullscreen {
    background: $es-loading-indicator-backdrop-color;
    left: 0;
    min-height: es-to-rem($es-loading-icon-size-large);
    min-width: es-to-rem($es-loading-icon-size-large);
    position: fixed;
    top: 0;
    z-index: $es-z-index-overlay;

    // show loading icon in large, when shown fullscreen
    & .es-loading-icon {
      margin: 0; // remove indicator and text harmonization
      height: es-to-rem($es-loading-icon-size-large);
      width: es-to-rem($es-loading-icon-size-large);
      &:after {
        border-width: es-to-rem($es-loading-icon-border-width-large);
      }
    }
  }

  // to make loading indicator (wrapper) visible (hidden by default)
  &.-es-visible {
    display: inline-block;
  }

  // backdrop when used as an overlay
  &.-es-backdrop {
    background: $es-loading-indicator-backdrop-color;
  }
  &.-es-backdrop-contrast {
    background: $es-loading-indicator-backdrop-contrast-color;
  }

  // when used as an overlay
  &.-es-overlay {
    left: 0;
    position: absolute;
    top: 0;
    z-index: $es-z-index-overlay;
  }

  // alignment of loading icon within the loading indicator wrapper
  &.-es-right {
      & .es-loading-icon {
        left: initial;
        right: 0;
        transform: translate(0, -50%);
      }
  }
  &.-es-left {
      & .es-loading-icon {
        left: 0;
        transform: translate(0, -50%);
      }
  }

  // size modifiers
  &.-es-small {
    min-height: $es-loading-icon-size-small;
    min-width: $es-loading-icon-size-small;

    // medium size loading indicator when in wrapper element
    & .es-loading-icon {
      height: es-to-rem($es-loading-icon-size-small);
      width: es-to-rem($es-loading-icon-size-small);
      &:after {
        border-width: es-to-rem($es-loading-icon-border-width-small);
      }
    }
  }
    
}

// (modifier) class for a parent container to position
// loading indicator container properly
.es-loadable-panel,
.-es-loadable {
  position: relative;
  
  // apply specific style to loading indicator wrapper container
  > .es-loading-indicator {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  // modifier to show embedded loading indicator
  &.-es-loading {
    > .es-loading-indicator {
      display: inline-block;
    }
  }
}

// global namespace class to turn off scrolling
.es-no-scrolling {
  overflow: hidden;
}

// loading icon animation
@keyframes loading-icon-rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
