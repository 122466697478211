//// ----------------------------------------------------------------------
///
/// Status sign Component
///
/// @group indicators
/// @module sass/components/indicators/status-sign
///
//// ----------------------------------------------------------------------

.es-status-sign {
  color: es-color('clario-mono','gray-11');
  text-align: center;

  & > span {
    display: block;
  }
  & > .es-icon {
    font-size: es-to-rem($es-icon-size-xxlarge);
  }
  span:first-child {
    margin: es-to-rem($es-space-stack-small);
  }

  &.-es-warning {
    color: $es-danger-color;

  }
  &.-es-information {
    color: $es-info-color;

  }
}

// extension when status sign used in placeholder container
.es-placeholder {
  .es-status-sign {
    color: inherit;
  }
}
