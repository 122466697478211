//// ----------------------------------------------------------------------
///
/// Form-field
///
/// @group forms
/// @module sass/components/forms/form-field
///
/// @require mixin es-em-calc
/// @require mixin es-clearfix
/// @require placeholder %es-control-invalid
/// @require placeholder %es-control-required-label-after
/// @require mixin es-viewport-range
//// ----------------------------------------------------------------------

.es-form-field {
    @include es-clearfix;
    width: 100%;

    // add default spacing, same spacing as space box
    padding: es-to-rem($es-space-small);

    // Fill padding for control height
    .es-checkbox,
    .es-radio {
      @extend %es-checkbox-radio-padding;
    }

    .es-switch {
      @extend %es-control-switch-padding-fill;
    }
  
    .es-form-message {
        // margin: es-to-rem($es-space-small 0 0 0);
        margin: 0;
    }
        
    #{$es-form-field-label-selector} {
        //    margin: es-to-rem($es-space-stack-small);
        padding: es-to-rem($es-space-small $es-space-small $es-space-small 0);
        line-height: es-to-rem($es-control-borderless-line-height);
    }
  
    #{$es-form-field-control-selector} {
        width: 100%;
    }

    .es-group {
        @include es-clearfix;
        margin-bottom: es-to-rem($es-space-small) * -1;
        // needed to add button here
        #{$es-form-field-control-selector} {
            margin: 0 es-to-rem($es-space-medium) es-to-rem($es-space-small) 0;
        }
        // TODO needs refactoring: Should be cleaned up in default group
        // special handling only when modified -es-inline-control is used.
        &.-es-inline-combined {
          #{$es-form-field-control-selector}, .es-button {
            margin: 0 es-to-rem($es-space-small) es-to-rem($es-space-small) 0;
          }

          // remove spacing on right on last child
          & *:last-child {
            margin-right: 0;
          }
        }

        // modifier to be used on child to
        // auto grow its width
        & .-es-auto-grow {
          flex-grow: 1;
        }

        // additional changes for buttons with icons
        & .es-button {
          white-space: nowrap;
        }
    }

  /*
    modifier "-es-invalid" for invalid fields
  */
  ///@require placeholder %es-control-invalid
  &.-es-invalid {
    .es-checkbox,
    .es-radio {
      label:before {
        @extend %es-control-invalid;
      }
    }
    .es-input,
    .es-select,
    .es-textarea {
      @extend %es-control-invalid;
    }
    .es-date-input,
    .es-time-input {
      input {
        @extend %es-control-invalid;
      }
      &:after {
        color: $es-date-input-invalid-color;
      }
    }
    .es-form-control-overlay {
      & > span {
        color: $es-date-input-invalid-color;
      }
    }
  }

  /*
    modifier "-es-required" for required fields
  */
  &.-es-required {
    .es-label:after {
      @extend %es-control-required-sign;
    }
  }
}
