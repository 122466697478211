//// ----------------------------------------------------------------------
///
/// Link list Modules
///
/// @group partials
/// @module sass/modules/components/link-list
///
//// ----------------------------------------------------------------------

%es-link-list {
    text-align: center;
    nav {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
            }

            li+li:before {
                content: "|";
                    margin: 0 es-to-rem($es-space-default);
            }
        }
    }
}