//// ----------------------------------------------------------------------
///
/// Widths modifier
///
/// @group atoms
/// @module sass/atoms
///
//// ----------------------------------------------------------------------

// Widths modifier
// @TODO needs to be removed
.-es-width-xxsmall,
.-es-width-xsmall,
.-es-width-small,
.-es-width-medium,
.-es-width-large,
.-es-width-xlarge,
.-es-width-xxlarge {
  width: 100%;
}

// Widths on tablet up
@include es-viewport-range(tablet-up){
  .-es-width-xxsmall {
    max-width: $es-width-xxsmall;
  }

  .-es-width-xsmall {
    max-width: $es-width-xsmall;
  }

  .-es-width-small {
    max-width: $es-width-small;
  }

  .-es-width-medium {
    max-width: $es-width-medium;
  }

  .-es-width-large {
    max-width: $es-width-large;
  }

  .-es-width-xlarge {
    max-width: $es-width-xlarge;
  }

  .-es-width-xxlarge {
    max-width: $es-width-xxlarge;
  }
}