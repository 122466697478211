//// ----------------------------------------------------------------------
///
/// Badge label
///
/// @group components
/// @module sass/partials/components/basics/badge-label
///
//// ----------------------------------------------------------------------

.es-badge-label {
  vertical-align: middle;

  label + .es-badge,
  .es-badge + label {
    margin: es-to-rem($es-space-gutter-left-small);
  }
}