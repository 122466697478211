//// ----------------------------------------------------------------------
///
/// Modal popup component
///
/// @group container
/// @module sass/components/containers/popup
///
/// @require function es-color
/// @require function es-get-icon
/// @require mixin es-em-calc
/// @require mixin es-overlay
//// ----------------------------------------------------------------------

$es-popup-max-width-small: 600px;
$es-popup-max-width-medium: 800px; // also the default
$es-popup-max-width-large: 1000px;

// popup by default not visible
.es-popup {
  display: none;
}

// to be set on body element, when a popup is open
.-es-popup-opened {
  overflow: hidden;
}

// open state of popup
.es-popup.-es-open {

  $es-popup-background-color: $es-background-color;
  // $es-popup-border-color: es-color('mono', 'oslo-grey', .8);
  $es-popup-button-close-default-color: $es-anchor-color;
  $es-popup-button-close-hover-color: $es-anchor-hover-color;
  $es-popup-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  $es-popup-header-footer-background-color: $es-background-color;
  $es-popup-header-icons-size: 22px;

  display: block;
  @include es-overlay;
  overflow-x: hidden;
  overflow-y: auto;

  .es-popup-content {
    background: $es-popup-background-color;
    box-shadow: $es-popup-shadow;
    // border: 1px solid $es-popup-border-color;
    margin: 0;
    transition: width .5s, top .5s, margin .5s;
    left: 0;
    right: 0;
    z-index: $es-z-index-topmost;
  }

  // modifiers to control width of popup
  &.-es-notification,
  &.-es-modal-small {
    .es-popup-content {
      max-width: $es-popup-max-width-small;
    }
  }

  &.-es-modal,
  &.-es-notification-medium {
    .es-popup-content {
      max-width: $es-popup-max-width-medium;
    }
  }


  &.-es-notification-large,
  &.-es-modal-large {
    .es-popup-content {
      max-width: $es-popup-max-width-large;
    }
  }


  .es-popup-header,
  .es-popup-footer {
    background-color: $es-popup-header-footer-background-color;
  }

  .es-popup-header {
    // for action bar
    @include es-clearfix;
    
    overflow-wrap: break-word;
    padding: es-to-rem($es-space-inset-default);
    position: relative;
    word-wrap: break-word; //IE
    border-bottom: 1px solid es-color('clario-mono','gray-7');

    // title
    h2 {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      width: auto;
    }

    // close button and action bar button stylings
    .es-popup-action-bar button,
    button[data-dismiss] {
      @extend %es-default-button-reset;
      color: $es-popup-button-close-default-color;
      vertical-align: middle;
      &:hover {
        color: $es-popup-button-close-hover-color;
      }

    }

    // close button
    button[data-dismiss] {
      bottom: 0;
      left: 0;
      padding: es-to-rem($es-space-inset-squish-default);
      position: absolute;
      top: 0;
      color: es-color('clario-mono','gray-12');
      &:hover{
        color: es-color('clario-mono','gray-12');
      }

    }
      
    // optional action bar
    .es-actionbar {
      float: right;
      padding: es-to-rem($es-space-small) 0;

      .es-icon {
        font-size: es-to-rem($es-popup-header-icons-size);
      }
    }
  }

  // Spacing for body
  .es-popup-body {
    @extend %es-container-inset-modifiers;
  }

  // Spacing for footer
  .es-popup-footer {
    @extend %es-container-inset-modifiers;

  }


  // closeable popup, reserve space for close icon
  &.-es-closeable {
    .es-popup-header {
      padding-left: es-to-rem($es-space-default + $es-icon-size + $es-space-default);
    }
  }

}

// @TODO: Revisit the different margin/width settings on different devices
// right now the popup does look too wide on a large screen
@include es-viewport-range(mobile-large-up) {
  .es-popup.-es-open {

    .es-popup-content {
      left: initial;
      right: initial;
      margin: es-to-rem($es-space-xlarge) auto;
      width: 80vw;
    }
    
  }
}