//// ----------------------------------------------------------------------
///
/// Nav item for sidebar navigation
///
/// @group navigation
/// @module sass/components/navigation/nav-item
/// @config components/nav-item
///
/// @require mixin es-to-rem
//// ----------------------------------------------------------------------

.es-nav-item,
.es-nav-item li {
  @include es-nav-item();
  @include es-nav-item-height($es-nav-item-height);
  border-bottom: 1px solid $es-nav-item-border-color;
  list-style-image: none;
  margin: 0;
  padding: 0;
  a,
  button {
    background-color: $es-nav-item-background-color;
    color: $es-nav-item-color;
    padding: 0 es-to-rem($es-space-default);
    text-align: left;
    text-decoration: none;
  }

  /*
  Help Link
  ----------------------------------------*/

  &.-es-help {
    border-bottom: 0;
    a, button {
      background-color: $es-sidebar-help-background-color;
    }
    .es-icon {
      font-size: es-to-rem($es-nav-item-icon-size-help);
      //help icon might differ in size. Add margins to harmonize alignment
      margin: 0 es-to-rem((calc(($es-nav-item-icon-size - $es-nav-item-icon-size-help) / 2)));
    }
  }
}

.es-nav-item.-es-active > a,
.es-nav-item li.-es-active > a,
.es-nav-item.-es-active > button,
.es-nav-item li.-es-active > button {
  background-color: $es-nav-item-background-color-active;
  border-bottom: 0;
  color: $es-nav-item-color-active;
  font-weight: 600;
  border-inline-start: 3px solid es-color('clario','purple-d');
}

.es-nav-item {
  background: $es-nav-item-background-color;
  position: relative;

  &:hover, &:focus,
  button:hover, button:focus,
  a:hover, a:focus,
  label:hover {
    background-color: $es-nav-item-background-color-hover;
    color: $es-nav-item-color-hover;
    cursor: pointer;
  }
}

/*
Nested Items
----------------------------------------
----------------------------------------*/

/*
chevron icons
*/
.es-nav-item.-es-open > a:before,
.es-nav-item.-es-open > button:before,
.es-nav-item.-es-closed > a:before,
.es-nav-item.-es-closed > button:before,
.es-nav-item.-es-collapsible > a:before,
.es-nav-item.-es-collapsible > button:before {
  @extend %es-ert-icon-singleton;
  $variance: 2px;
  font-size: es-em-calc($es-nav-item-chevron-size);
  position: absolute;
  right: $es-nav-item-chevron-right;
  top: ($es-nav-item-height - $es-nav-item-chevron-size) * .5 + $variance;
}

.es-nav-item ul {
  border-top: 1px solid $es-nav-item-border-color;
  margin-top: -1px;
  li {
    a, button {
      background-color: $es-nav-item-background-color;
      font-weight: normal;
    }
    // a:hover, a:focus,
    // button:hover, button:focus {
    //   //background-color: $es-nav-item-background-color-hover;
    // }
  }
}

/*
Closed:
----------------------------------------*/

/*
closed nested items
*/
.es-nav-item.-es-closed ul,
  /*closed by default in css-collapsible variant*/
.es-nav-item.-es-collapsible ul {
  @include es-transition-collapse-vertical();
  transition: $es-nav-item-transition-duration;
}

/*
chevron-down on parent item
*/
.es-nav-item.-es-closed > a:before,
.es-nav-item.-es-closed > button:before,
  /*css-collapsible variant*/
.es-nav-item.-es-collapsible > a:before,
.es-nav-item.-es-collapsible > button:before {
  content: es-get-icon('chevron-down');
}

/*
Open:
----------------------------------------*/

/*
css-collapsible variant (open on focus)
*/
.es-nav-item.-es-collapsible > a:focus + ul,
.es-nav-item.-es-collapsible > button:focus + ul {
  @include es-transition-expand-vertical($es-nav-item-height * 5);
}

/*
chevron-up on parent items
*/
.es-nav-item.-es-open > a:before,
.es-nav-item.-es-open > button:before,
  /*css-collapsible variant*/
.es-nav-item.-es-collapsible > a:focus:before,
.es-nav-item.-es-collapsible > button:focus:before {
  content: es-get-icon('chevron-up');
}

@include es-viewport-range(desktop-up) {

  /*
  open nested items on hover in css-collapsible variant
  */
  .es-nav-item.-es-collapsible:hover > a + ul,
  .es-nav-item.-es-collapsible:hover > button + ul {
    @include es-transition-expand-vertical($es-nav-item-height * 5);
  }

  /*
  close nested items if not hovered in css-collapsible variant
  */
  .es-nav-item.-es-collapsible:not(:hover) > a:focus + ul,
  .es-nav-item.-es-collapsible:not(:hover) > button:focus + ul {
    @include es-transition-collapse-vertical();
  }
}

