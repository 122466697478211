//// ----------------------------------------------------------------------
///
/// mixins, functions or placeholder for widgets/controls
///
/// @group modules
/// @module sass/modules/components/controls
///
/// @require mixin es-color
/// @require mixin es-to-rem
/// @require mixin es-get-icon
/// @require mixin es-control-icon
/// @require mixin es-icon
//// ----------------------------------------------------------------------

///Mixins for displaying icons next to the control's content
@mixin es-control-icon($icon, $size: $es-icons-font-size-base){
  @include es-icon(ert-icons);
  content: es-get-icon($icon);
  font-size: es-to-rem($size);
  pointer-events: none;
}

@mixin es-control-icon-after($icon, $inset: $es-space-inset-squish-default, $size: $es-icons-font-size-base) {
  position: relative;
  &:after {
    @include es-control-icon($icon, $size);
    padding: es-to-rem($inset);
    position: absolute;
    right: 0;
// @TODO:   due to optical alignment issues top:0 was removed temporarily
//          needs further investigation if icon font creation produces incorrect results
//    top: 0;
    // harmonize icons and text vertically
    top: (es-to-rem(1));
    bottom: 0;
  }
}

@mixin es-control-icon-before($icon, $inset: $es-space-inset-squish-default, $size: $es-icons-font-size-base) {
  position: relative;
  &:before {
    @include es-control-icon($icon, $size);
    padding: es-to-rem($inset);
    position: absolute;
    left: 0;
    // @TODO:   due to optical alignment issues top:0 was removed temporarily
    //          needs further investigation if icon font creation produces incorrect results
    //    top: 0;
    // harmonize icons and text vertically
    top: (es-to-rem(1));
    bottom: 0;
  }
}

%es-control-basic {
  $border-color: $es-control-border-color;
  $color: $es-control-color;

  background: $es-background-color;
  border: $es-control-border-width solid #{$border-color};
  color: #{$color};
  display: inline-block;
  margin: 0;
  transition: border-color .25s;
  outline: 0;
}

// Extensions for controls

%es-control {
  @extend %es-control-basic;
  @extend %es-control-ios-css-reset;
  // IE specific setting: 
  // Controls look odd in height in IE, when not set
  // height: es-to-rem($es-control-height);  // This override was messing with invalid borders
  &.-es-invalid {
    @extend %es-control-invalid;
  }
  &.-es-disabled {
    @extend %es-control-disabled;
  }
  &:disabled,
  &:disabled:hover {
    @extend %es-control-disabled;
  }
  &:focus {
    @extend %es-control-focus;
  }
  &:hover {
    @extend %es-control-hover;
  }
}

%es-control-invalid {
  border: 2px solid #{$es-control-border-color-invalid};
}

%es-control-disabled {
  background-color: $es-control-disabled-color;
  border-color: $es-control-border-color-disabled;
  cursor: not-allowed;
  opacity: .9;
}

%es-control-focus {
  border-color: $es-control-border-color-focus;
  box-shadow: $es-control-shadow-focus;
}

%es-control-hover {
  border-color: $es-control-border-color-hover;
}

%es-caret {
  @include es-control-icon-after(chevron-down);
}

// Required "*" field indicator
%es-control-required-sign {
  color: $es-control-required-color;
  content: "*";
  font-weight: bold;
  margin-left: es-to-rem($es-space-small);
}


