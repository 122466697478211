//// ----------------------------------------------------------------------
///
/// Form controls components
///
/// @group form-controls
/// @module sass/components/form-controls/form-controls
/// @require placeholder %es-checkbox-radio
/// @require placeholder %es-control
/// @require placeholder %es-control-hover
/// @require placeholder %es-control-invalid
/// @require placeholder %es-control-required-label-after
/// @require mixin es-em-calc
///
//// ----------------------------------------------------------------------

/*
 file-input for file uploader/presenter
*/
.es-file-control {
  display: table;

  // hide actual file input button
  .es-hidden-file-input {
    display: table-cell;
    max-width: 0;

    input {
      height: 0;
      visibility: hidden;
      width: 0;
    }
  }

  .es-file-name {
    display: table-cell;
    // margin does not work in table cell
    padding-right: es-to-rem($es-space-small);
    vertical-align: middle;
    width: 100%;

    // apply input control styles to embedded file input
    > input,
    .es-file-input {
      @extend %es-input;
      width: 100%;
      border-radius: $es-control-border-radius;
    }
  }

  .es-action {
    display: table-cell;
    vertical-align: middle;

    & > .es-button {
      // Remove border radius in safari
      @extend %es-control-ios-css-reset;
      border-radius: $es-control-border-radius;
    }
  }
}

/*
 basic form input
*/

.es-input {
  @extend %es-input;
  background-color: $es-control-field-background-color;
  border-radius: $es-control-border-radius;
}

// Text area

.es-textarea {
  @extend %es-control;
  min-height: es-to-rem($es-control-height * 2 + $es-space-small * 2);
  padding: es-to-rem($es-space-inset-squish-default);
  resize: none;
  vertical-align: top; // removes extra space below textarea when stacked (not aligned)
  background-color: $es-control-field-background-color;
  border-radius: $es-control-border-radius;

  &.-es-resize {
    resize: vertical;
  }
}

///checkbox and radio button
.es-checkbox,
.es-radio {
  &.-es-invalid {
    label:before {
      @extend %es-control-invalid;
    }
  }
  input[disabled],
  input:disabled:hover {
    & + label:before {
      @extend %es-control-disabled;
    }
    &:checked + label:after {
      opacity: $es-control-disabled-opacity;
    }
  }
}


///checkbox
.es-checkbox {
  @extend %es-checkbox-radio;
  input {
    &:checked + label:after {
      @include es-icon(ert-icons);
      color: es-color("clario-mono", "gray-12");
      content: es-get-icon(row-checkmark);
    }
  }
  input:indeterminate {
    & + label:after {
      background-color: es-color("clario-mono", "gray-12");
      height: 1rem;
      top: 0.5rem;
      left: 0.5rem;
      bottom: 0.5rem;
      width: 1rem;
    }
  }
  &.-es-required label:after {
    @extend %es-control-required-sign;
    margin-left: es-to-rem($es-space-small);
  }
}

///radio button
.es-radio {
  @extend %es-checkbox-radio;
  $bullet-inset: $es-space-small;
  $bullet-size: es-to-rem($es-checkbox-size - 2 * $bullet-inset);
  $bullet-offset: es-to-rem($bullet-inset);
  input {
    // make border of background round
    & + label:before {
      border-radius: 100%;
    }
    &:checked + label:after {
      background-color: es-color("clario-mono", "gray-12");
      border-radius: 100%;
      height: $bullet-size;
      top: $bullet-offset;
      left: $bullet-offset;
      bottom: $bullet-offset;
      width: $bullet-size;
    }
  }
}

.es-select {
  // padding is inset squish, but has to be calculated manually
  // because of the caret at the right
  $select-inset-right: $es-icon-size + 2*$es-space-default;
  $select-inset: $es-space-small $select-inset-right $es-space-small $es-space-medium;

  @extend %es-control;
  @extend %es-caret;
  border: none;
  select {
    appearance: none;
    background-color: $es-control-field-background-color;
    border: 1px solid es-color('clario-mono', 'gray-8');
    border-radius: $es-control-border-radius;
    padding: es-to-rem($select-inset);
    width: 100%; // ensure that "hidden" select field still consumes 100% width;
    &:focus {
      outline: none;
      border: 1px auto es-color('clario-mono','gray-11');
      box-shadow: $es-control-shadow-focus;
    }
  }
  &.-es-invalid select{
    border: none;

  }

  &.-es-invalid{
    border-radius: $es-control-border-radius;
    overflow: hidden;
  }
  &.-es-disabled:hover,
  & > select:disabled:hover {
    @extend %es-control-disabled;
  }

  // Styles for placeholder
  select.-es-placeholder {
    color: $es-inactive-color;
  }
  option[default] {
    display: none;
  }

  //hide system caret in IE
  select::-ms-expand{
    display: none;
  }
  //hide icon-font-caret in IE lt 10
  .es-ie9 &:after{
    display: none;
  }
  .es-ie9 & select{
    padding-right: .25em;
  }
  &:hover {
    @extend %es-control-hover;
  }
}




//labels for form-elements

.es-label {
  display: inline-block;
  &.-es-required:after {
    @extend %es-control-required-sign;
    margin-left: es-to-rem($es-space-small);
  }
  color: es-color('clario-mono','gray-12')
}

.es-label-stack {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  
  span {
    color: inherit;
    display: block;
    line-height: es-to-rem($es-control-height * .5);
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.es-label-title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  h1, h2, h3, h4 {
    line-height: es-to-rem($es-control-height);
  }
}

/*
  Text boxes - read only form component
*/
.es-textbox {
  display: inline-block;

  //Overflow handling
  @include es-overflow-wrap;
}
