//// ----------------------------------------------------------------------
///
/// Inline message
///
/// @group components
/// @module sass/partials/components/alerts/inline-message
///
//// ----------------------------------------------------------------------

// Variables for inline-message

.es-inline-message {
    // variant: default 
    $es-inline-message-background-color: es-color('clario-blue', 'blue-4');
    $es-inline-message-text-color: es-color('clario-blue', 'blue-11');

    // variant: error
    $es-inline-message-background-color-error: es-color('clario-red', 'red-4');
    $es-inline-message-text-color-error: es-color('clario-red', 'red-9');

    display: flex;
  
    background: $es-inline-message-background-color;
    color: $es-inline-message-text-color;
    margin: 0;
    padding-inline: 1.25rem;
    padding-block: 1rem;
    border-radius: 4px;
    border: 1px solid es-color('clario-blue','blue-7');
    align-items: stretch; 
  
    @extend %es-word-wrap-break-word;
  
    // stack spacing for paragraphs within message
    p {
      margin: es-to-rem($es-space-stack-default);
  
      &:last-child {
        margin: 0;
      }
    }
  
    &.-es-error {
      background: $es-inline-message-background-color-error;
      color: $es-inline-message-text-color-error;
      border-color: es-color('clario-red','red-7')
    }

  & > span.es-icon {
    font-size: es-to-rem($es-icon-size-xlarge);
    margin: es-to-rem($es-space-gutter-right-small);
    vertical-align: top;
  }

  p {
    display: inline-block;
    //Overflow handling
    @include es-overflow-wrap;
    word-break: break-all;
  }
}

@include es-viewport-range(mobile-large-up){
  .es-inline-message {
    display: inline-block;
  }
}

// inline message following a paragraph
p + .es-inline-message {
  margin: es-to-rem($es-space-stack-large);
}