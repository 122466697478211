//// ----------------------------------------------------------------------
///
/// ERT dashboard page with sidebar navigation
///
/// @group partials
/// @module sass/pages/dashboard-with-sidebar
///
//// ----------------------------------------------------------------------


// @TODO: needs to be refactored after sidebar is done!

@import "../../_configuration/components/_nav-item";
@import "../../_configuration/components/_sidebar";
@import "../../components/navigation/_sidebar";

.es-app {

  /*Position (Mobile)
  ----------------------------------------
  ----------------------------------------*/

  .es-sidebar {
    //the width of sidebar container must not exceed the width of the sidebar toggle button
    //otherwise the header is not clickable anymore
    max-width: es-to-rem($es-page-header-height);
    position: fixed;
    top: 0;
    z-index: $es-sidebar-z-index-mobile;
  }

  /*
  Sidebar Closed
  ----------------------------------------
  ----------------------------------------*/

  .es-sidebar.-es-closed {
    .es-nav-items {
      left: es-to-rem($es-sidebar-width-closed) * -1;
      position: absolute;
    }
  }

  /*
  Sidebar Opened
  ----------------------------------------
  ----------------------------------------*/

  .es-sidebar.-es-open {
    .es-nav-items {
      left: 0;
    }
  }

  /*
  Conflicts
  ----------------------------------------
  ----------------------------------------*/

  //make sure that the footer is not overlapped by the sidebar
  .es-copyright-footer {
    z-index: $es-sidebar-z-index-tablet + 1;
  }


  /* Mobile Only: Display Toggle Button in Header
  ----------------------------------------
  ----------------------------------------*/

  @include es-viewport-range(mobile-only) {
    .es-sidebar {
      .es-toggle {
        height: es-to-rem($es-sidebar-toggle-height-mobile);
        position: relative;
        width: es-to-rem($es-sidebar-toggle-width-mobile);

        button {
          padding: 0;
          text-align: center;
        }
      }
      .es-toggle .es-icon {
        //font-size: es-to-rem($es-global-header-height - 2 * $es-gutter);
        //margin-top: $es-gutter;
      }
    }
  }



  /*
  Tablet
  ----------------------------------------
  ----------------------------------------*/

  @include es-viewport-range(mobile-large-up) {
    .es-sidebar {
      height: calc(100vh - #{es-to-rem($es-global-header-height)});

      //sidebar should have the same min-height as .es-page-main
      min-height: es-to-rem($es-page-main-min-height);

      //position below header
      position: fixed;
      top: es-to-rem($es-page-header-height);

      //transition for opening/closing the sidebar
      transition: min-width $es-nav-item-transition-duration;

      //reset width to allow opening/closing using transition on max-width
      width: auto;

      //don't overlap app launcher menu
      z-index: $es-global-header-z-index - 1;

      .es-nav-items {
        //override position on mobile
        position: static;
      }

      /* Sidebar Open
      ----------------------------------------*/

      &.-es-open {
        //override position on mobile
        left: 0;
      }

      /* Sidebar Closed
      ----------------------------------------*/

      &.-es-closed {

        .es-toggle {
          //override mobile styles
          position: static;
        }

        .es-nav-items {
          //override mobile styles
          left: 0;
        }
      }

      //push es-page-main to the right and shrink it
      & + .es-app-body:not(.-es-fixed-width) {
        //use min-width to enable transition
        min-width: calc(100% - #{es-to-rem($es-sidebar-width-closed)});
        margin-left: es-to-rem($es-sidebar-width-closed);
      }
    }
  }

  //push es-page-main to the right for fixed width wide content
  @media (min-width: $es-mobile-max) and (max-width: $es-page-main-medium-max-width + 2 *  $es-sidebar-width-closed) {
    .es-sidebar + .es-app-body.-es-fixed-width {
      margin-left: es-to-rem($es-sidebar-width-closed);
    }
  }

  /*
  Desktop
  ----------------------------------------
  ----------------------------------------*/

  @include es-viewport-range(desktop-up) {

    .es-sidebar {
      & + .es-app-body {
        //transition for opening/closing the sidebar
        transition: min-width, margin-left $es-nav-item-transition-duration;
      }
    }
  }
}