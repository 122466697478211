//// ----------------------------------------------------------------------
///
/// Form message component
///
/// @group forms
/// @module sass/components/forms/forms
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

/*
 form messages for field validation messages and form messages etc.
*/

.es-form-message {
  // variant: default 
  $es-form-message-background-color: transparent;
  $es-form-message-text-color: es-color('clario-mono', 'gray-11');

  // variant: invalid
  $es-form-message-background-color-invalid: transparent;
  $es-form-message-text-color-invalid: es-color('clario-red', 'red-9');

  // @TODO: Removed until discussions about color scheme are solved
  // variant: Warning
  $es-form-message-background-color-warning: es-color('red', 'tuft-bush');
  $es-form-message-background-color-warning: es-color('mono', 'white');
  $es-form-message-text-color-warning: $es-warning-color;
  
  // variant: error
  $es-form-message-background-color-error: transparent;
  $es-form-message-text-color-error: es-color('clario-red', 'red-9');

  background: $es-form-message-background-color;
  // border: 1px solid;
  color: $es-form-message-text-color;
  margin: 0;
  padding: es-to-rem($es-space-inset-squish-default);

  @extend %es-word-wrap-break-word;

  // stack spacing for paragraphs within message
  p {
    margin: es-to-rem($es-space-stack-default);

    &:last-child {
      margin: 0;
    }
  }

  &.-es-error {
    background: $es-form-message-background-color-error;
    color: $es-form-message-text-color-error;
    a {
      color: $es-form-message-text-color-error;
      &:hover {
        text-decoration: none;
        color: es-color('clario-red', 'red-12')
      }
    }

  }
  &.-es-invalid {
    background: $es-form-message-background-color-invalid;
    color: $es-form-message-text-color-invalid;
  }
  /* // @TODO: Removed until discussions about color scheme are solved
  &.-es-warning {
    background: $es-form-message-background-color-warning;
    color: $es-form-message-text-color-warning;
  }*/
}

