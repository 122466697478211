//// ----------------------------------------------------------------------
///
/// Notification
///
/// @group components
/// @module sass/components/alerts/notification
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

// <div clas="es-notification -es-info">
//   <span class="es-icon es-icon-info">
//     ::before
//   </span>
//   <p>This is an info message</p>
//   <span class="es-icon es-icon-info"></span>
// </div>




.es-notification {
  color: $es-font-color;
  border: 1px solid es-color('clario-blue','blue-7');
  background-color: es-color('clario-blue','blue-4');
  display: flex;
  align-items: stretch;
  align-content: center;
  padding-block: 1rem;
  padding-inline: 1.25rem;
  gap: 1.25rem;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);

  p {
    margin:0;
    flex-grow: 1;

  a {
      color: $es-font-color;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  span.es-icon {
    align-self: center;
    color: es-color('clario-blue','blue-11');
    
    &.es-icon-close {
      color: es-color('clario-mono','gray-12');
      transition: 0.2s;
      cursor: pointer;
    }
  }

  // Animation.
  &.-es-animated {

    animation-duration: 0.5s;
    animation-fill-mode: both;

    &.-es-slide-in-right {
      animation-name: slide-in-right;
    }

    @keyframes slide-in-right {
      from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes fade-in-down {
      from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
      }

      to {
        opacity: 1;
        transform: none;
      }
    }

    &.-es-fade-in-down {
      animation-name: fade-in-down;
    }
  }

  &.-es-warning {
    border: 1px solid es-color('clario-yellow','yellow-7');
    background-color: es-color('clario-yellow','yellow-4');

    span.es-icon {
      color: es-color('clario-yellow','yellow-11');
    }

    span.es-icon-close {
      color: es-color('clario-mono','gray-12');
    }
  } 

  &.-es-info {
    border: 1px solid es-color('clario-blue','blue-7');
    background-color: es-color('clario-blue','blue-4');

    span.es-icon {
      color: es-color('clario-blue','blue-11');
    }

    span.es-icon-close {
      color: es-color('clario-mono','gray-12');
    }
  } 

  &.-es-success {
    border: 1px solid es-color('clario-teal','teal-7');
    background-color: es-color('clario-teal','teal-4');

    span.es-icon {
      color: es-color('clario-teal','teal-11');
    }

    span.es-icon-close {
      color: es-color('clario-mono','gray-12');
    }
  } 

  &.-es-danger {
    border: 1px solid es-color('clario-red','red-7');
    background-color: es-color('clario-red','red-3');

    span.es-icon {
      color: es-color('clario-red','red-11');
    }

    span.es-icon-close {
      color: es-color('clario-mono','gray-12');
    }
  } 
}






// .es-notification {

//   //Notification variables
//   $es-notification-color: es-color("clario-mono", "gray-9");
//   color: $es-font-color;

//   //Mixin for notification type color and style
//   @mixin es-notification-type($color){
//     border-color: $color;
//     color: $es-font-color;
//     & .es-icon:first-child {
//       background-color: $color;
//     }
//     & p {
//       a {
//         color: $color;
//       }
//     }
//   }

//   background-color: $es-background-color;
//   border: 1px solid $es-border-color;
//   color: $es-font-color;
//   display: flex;
//   padding: es-to-rem($es-space-inset-xsmall);

//   // Notification main icon
//   & .es-icon:first-child {
//     background-color: $es-notification-color;
//     color: $es-font-bright-color;
//     font-size: es-to-rem($es-icon-size-xlarge);
//     padding: es-to-rem($es-space-inset-default);
//   }

//   // Notification close icon
//   & .es-icon:last-child {
//     color: $es-font-color;
//     cursor: pointer;
//     font-weight: bold;
//     padding: es-to-rem($es-space-inset-small);
//     transition: 0.2s;

//     &:hover {
//       color: $es-font-subtle-color;
//     }
//   }

//   & p {
//     align-self: center;
//     flex: 1;
//     margin: es-to-rem($es-space-gutter-left-default);

//     //Overflow handling
//     @include es-overflow-wrap;
//     overflow-x: auto;

//     a {
//       color: $es-font-color;
//       font-weight: bold;
//       text-decoration: underline;
//     }
//   }

//   // Notification types

//   &.-es-danger {
//     border-color: es-color('clario-red','red-7');
//     color: $es-font-color;
//     & .es-icon:first-child {
//       background-color: es-color('clario-red','red-3');
//     }
//     & p {
//       a {
//         color: $color;
//       }
//     }
//   }

//   &.-es-info {
//     @include es-notification-type($es-info-color);
//   }

//   &.-es-success {
//     @include es-notification-type($es-success-color);
//   }

//   &.-es-warning {
//     @include es-notification-type($es-warning-color);
//   }