//// ----------------------------------------------------------------------
///
/// Bullet list
///
/// @group configuration
/// @module sass/components/basics/bullet-list
///
/// @require function es-color
//// ----------------------------------------------------------------------

// icon sizes for bullets
$es-bullet-item-icon-size-small:   10px;
$es-bullet-item-icon-size-large:   17px;


.es-bullet-list {
  @extend %es-reset-ul;
  @extend %es-bullet-default-color-modifiers;
  
  li {
    .es-icon,
    &:before {
      padding: es-to-rem($es-space-gutter-right-small);
    }

    &:not(:last-child) {
      margin: es-to-rem($es-space-stack-small);
    }
  }

  // default icons for yes/no bullets
  &.-es-yes-no {
    margin-left: es-to-rem($es-space-large);

    li {
      // indent by default so text is horizontally aligned when wrapped
      padding: es-to-rem(0 0 0 $es-space-large);
      text-indent: es-to-rem($es-space-default * -1);
    }

    & .es-bullet-yes {
      &:before {
        @include es-control-icon(complete);
      }
    }
  
    & .es-bullet-no { 
      &:before {
        @include es-control-icon(missed);
      }
    }
  }

  // small icons
  &.-es-icons-small {
    li {
      .es-icon,
      &:before {
        font-size: es-to-rem($es-bullet-item-icon-size-small);
      }
    }
  }
  
  // large icons
  &.-es-icons-large {
    li {
      .es-icon,
      &:before {
        font-size: es-to-rem($es-bullet-item-icon-size-large);
      }
    }
  }

}
  