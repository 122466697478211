
//// ----------------------------------------------------------------------
///
/// Tag Component
///
/// @group component
/// @module sass/components/basics/tag
///
//// ----------------------------------------------------------------------

.es-tag {
  //Variables for tags
  $es-tag-background-color: es-color("clario-mono", "gray-3");
  $es-tag-background-hover-color: es-color("clario-mono", "gray-4");
  $es-tag-background-focus-color: es-color("clario-mono", "gray-5");
  $es-tag-close-color: es-color('clario-mono','gray-11');
  $es-tag-close-hover-color: es-color('clario-mono','gray-12');
  $es-tag-icon-line-height: es-to-rem($es-line-height);
  $es-tag-border-radius: es-to-rem(26px);

  color: $es-font-color;
  background-color: $es-tag-background-color;
  display: inline-block;
  padding: es-to-rem($es-space-inset-squish-default);
  text-align: center;
  border-radius: $es-tag-border-radius;
  text-decoration: none;

  & > span:first-child {
    color: $es-font-bright-color;
  }

  & > .es-icon {
    color: $es-tag-close-color;
    margin: es-to-rem($es-space-gutter-left-small);
    vertical-align: middle;
  }

  &:focus{
    color: $es-font-color;
  }

  &.-es-close {
    &:after {
      @include es-control-icon(close);
      color: $es-tag-close-color;
      font-size: es-to-rem($es-icon-size-small);
      margin: es-to-rem($es-space-gutter-left-small);
      vertical-align: top;
    }
  }

  &:hover{
    background-color: $es-tag-background-hover-color;
    color: $es-font-color;
    cursor: pointer;
    text-decoration: none;

    &:after {
      color: $es-font-color;
    }
    & > .es-icon {
      color: $es-font-color;
    }
  }
  &:active {
    background-color: $es-tag-background-focus-color;
    color: $es-font-color;
    &:after {
      color: $es-font-bright-color;
    }
    & > .es-icon {
      color: $es-font-bright-color;
    }
  }

  &.-es-informative {
    background-color: es-color('clario-blue','blue-3');
    color: es-color('clario-blue','blue-11');
    &:hover{
      background-color: es-color('clario-blue','blue-4');
      color: es-color('clario-blue','blue-12');
    }
  }

  &.-es-positive {
    background-color: es-color('clario-teal','teal-3');
    color: es-color('clario-teal','teal-11');
    &:hover{
      background-color: es-color('clario-teal','teal-4');
      color: es-color('clario-teal','teal-12');
    }
  }

  &.-es-negative {
    background-color: es-color('clario-red','red-3');
    color: es-color('clario-red','red-11');
    &:hover{
      background-color: es-color('clario-red','red-4');
      color: es-color('clario-red','red-12');
    }
  }

  &.-es-notice {
    background-color: es-color('clario-yellow','yellow-3');
    color: es-color('clario-yellow','yellow-11');
    &:hover{
      background-color: es-color('clario-yellow','yellow-4');
      color: es-color('clario-yellow','yellow-12');
    }
  }

  &.-es-brand {
    background-color: es-color('clario-purple','purple-3');
    color: es-color('clario-purple','purple-11');
    &:hover{
      background-color: es-color('clario-purple','purple-4');
      color: es-color('clario-purple','purple-12');
    }
  }
}

a.es-tag {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}