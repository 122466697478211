//// ----------------------------------------------------------------------
///
/// Pagination (pager) component
///
/// @group navigation
/// @module sass/components/navigation/pagination
///
//// ----------------------------------------------------------------------

// Pager for page control

.es-pagination {
  // Variables for pagination
  $es-pagination-number-line-height: es-to-rem($es-line-height + 2px);
  $es-pagination-number-width: 35px;

  display: inline-flex;

  & .es-button {
    border: none;
    box-shadow: none;
  }

  // ensure buttons are connected like a group
  // & .es-button + .es-button {
  //   margin-left: es-to-rem($es-control-border-width * -1);
  // }

  .es-number {
    display: inline-block;
    min-width: es-to-rem($es-pagination-number-width);
    overflow: hidden;
    padding: es-to-rem($es-space-inset-squish-default);
    text-align: center;
    white-space: nowrap;
  }
}

