//// ----------------------------------------------------------------------
///
/// Data type alignment principle
///
/// @group partials
/// @module sass/partials/atoms/badge
///
//// ----------------------------------------------------------------------

.es-data-date,
.es-data-text {
  text-align: left;
}

.es-data-number {
  text-align: right;
}