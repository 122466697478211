//// ----------------------------------------------------------------------
///
/// Popover modules
///
/// @group partials
/// @module sass/modules/components/popover
///
//// ----------------------------------------------------------------------

@mixin es-popover-content-open {
	$es-popover-width-default: 28 * es-to-rem($es-space-default);
	$es-popover-border-color: $es-border-color;
	$es-popover-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);

	border: 1px solid $es-popover-border-color;
	box-shadow: $es-popover-shadow;
	left: calc(100% + #{$es-space-small});
	top: 50%;
	transform: translateY(-50%);
	width: $es-popover-width-default;
    display: block;
    margin: 0;
    position: absolute;
    z-index: $es-z-index-topmost;
}

%es-popover {
	$es-popover-width-small: 16 * $es-space-default;
	$es-popover-width-large: 40 * $es-space-default;

    display: inline-block;
    position: relative;

	&.-es-open.-es-small .es-popover-content {
		width: $es-popover-width-small;
	}
	&.-es-open.-es-large .es-popover-content {
		width: $es-popover-width-large;
	}
    &.-es-open .es-popover-content {
        @include es-popover-content-open;

		background-color: $es-background-color;
		overflow-y: auto;
		a,
		button {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
    }
    .es-popover-content {
		display: none;
    }
}
