//// ----------------------------------------------------------------------
/// Non representational
/// color variables
///
/// @group configuration
/// @module sass/configuration/foundation/colors
///
//// ----------------------------------------------------------------------

// Basic colors

/// colors
//// ----------------------------------------------------------------------

//brand
$es-brand-main: es-color('clario-purple', 'purple-brand');
$es-brand-accent: es-color('clario-pink', 'bright-pink');

// Status
$es-danger-color: es-color("clario-red", "red-9");
$es-inactive-color: es-color("clario-mono", "gray-7");
$es-info-color: es-color("clario-blue", "blue-9");
$es-success-color: es-color("clario-teal", "teal-9");
$es-warning-color: es-color("clario-yellow", "yellow-9");
$es-warning-light-color: es-color('clario-red', 'red-3');
$es-highlight-color: es-color("clario-blue", "blue-9");
$es-disabled-color: es-color("clario-mono", "gray-7");
$es-visited-color: es-color('clario-mono', 'gray-11');

//translucent
$es-glass-color: es-color('clario-mono', 'white', 0.2);

//font
$es-font-color: es-color('clario-mono', 'gray-12');
$es-font-alt-bright-color: es-color('green', 'lime'); // not used
$es-font-bright-color: es-color('clario-mono', 'white');
$es-font-important-color: es-color("clario-purple", "purple-11");
$es-font-subtle-color: es-color('clario-mono', 'gray-11');
$es-font-warning-color: es-color("clario-red", "red-11");

//anchor
$es-anchor-color: es-color('clario-blue', 'blue-11');
$es-anchor-hover-color: es-color('clario-blue', 'blue-12');
$es-anchor-focus-color: es-color('clario-blue', 'blue-11');
$es-anchor-visited-color: es-color('clario-blue', 'blue-11');
// anchor colors for dark backgrounds
$es-anchor-contrast-color: es-color('clario-mono', 'white');
$es-anchor-contrast-hover-color: es-color('clario-mono', 'gray-5');
$es-anchor-contrast-focus-color: es-color('clario-mono', 'white');
$es-anchor-contrast-visited-color: es-color('clario-mono', 'white');

//backgrounds
$es-background-color: es-color("clario-mono", "white");
$es-background-attention-color: es-color('clario-red', 'red-3');
$es-background-contrast-color: es-color('clario-mono', 'gray-2');
$es-background-disabled-color: es-color("clario-mono", "gray-6");
$es-background-light-color: es-color("clario-mono", "gray-1");

//border
$es-border-color: es-color('clario-mono', 'gray-7');
$es-border-bright-color: es-color("clario-mono", "white");
$es-border-light-color: es-color('clario-mono', 'gray-4');
$es-border-attention-color: es-color("clario-red", "red-9");
$es-border-separator-color: es-color('clario-mono', 'black', .2); 
$es-border-highlight-color: es-color("clario-pink", "bright-pink");

//shadow
$es-shadow-color: es-color('clario-mono', 'black', .2);

//icons
$es-icon-color: es-color('clario-mono','gray-11');