//// ----------------------------------------------------------------------
///
/// Layout System Base
///
/// @group molecules
/// @module scss/molecules
///
//// ----------------------------------------------------------------------



.es-text-group {
  
  & > *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    overflow-wrap: break-word;
  }
}
