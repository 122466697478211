.es-invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  @media print {
    display: none;
  }
  /*
 * Extends the .es-invisible class to
 * allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */
  &.-es-focusable:active,
  &.-es-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

