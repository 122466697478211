//// ----------------------------------------------------------------------
///
/// Widths configuration file
///
/// @group configuration
/// @module sass/configuration/foundation/widths
///
//// ----------------------------------------------------------------------

// Widths

$es-width-xxsmall:    10*$es-pixel-unit;
$es-width-xsmall:     15*$es-pixel-unit;
$es-width-small:      20*$es-pixel-unit;
$es-width-medium:     40*$es-pixel-unit;
$es-width-large:      60*$es-pixel-unit;
$es-width-xlarge:     80*$es-pixel-unit;
$es-width-xxlarge:    100*$es-pixel-unit;

/// Widths
//// ----------------------------------------------------------------------
$es-container-width-none:       0*$es-pixel-unit;
$es-container-width-xsmall:     32*$es-pixel-unit;
$es-container-width-small:      64*$es-pixel-unit;
$es-container-width-medium:     96*$es-pixel-unit;
$es-container-width-large:      128*$es-pixel-unit;
$es-container-width-xlarge:     160*$es-pixel-unit;