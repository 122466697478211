//// ----------------------------------------------------------------------
///
/// Dropdown Menu modules
///
/// @group partials
/// @module sass/modules/components/dropdown
///
//// ----------------------------------------------------------------------
@mixin es-dropdown-content {
    @include es-transition-hide;
}

@mixin es-dropdown-content-open {
    // top = default space above and below icon + line height of icon/text
    $dropdown-top: 3 * $es-space-default + $es-control-line-height;
    $dropdown-tablet-max-visible-items-default: 15; // maximum number of visible items on tablet and above
    
    @include es-transition-show;
    display: block;
    left: es-to-rem($es-space-default);
    margin: 0;
    position: fixed;
    right: es-to-rem($es-space-default);
    top: es-to-rem($dropdown-top);
    max-height: calc(100vh - #{es-to-rem($dropdown-top + $es-space-default)});
    transition: none;
    z-index: $es-z-index-topmost;
    @include es-viewport-range(mobile-large-up) {
        display: inline-block;
        height: auto;
        left: 0;
        margin: 0;
        min-width: 160px;
        position: absolute;
        right: auto;
        max-height: es-to-rem($es-control-height * $dropdown-tablet-max-visible-items-default);
        top: calc(100% + #{$es-space-xsmall});
        width: auto;
        z-index: $es-page-header-z-index - 1;
    }
}

@mixin es-dropdown-overlay {
    @include es-viewport-range(mobile-large-up) {
        &:before {
            display: none;
        }
    }
}

@mixin es-dropdown-overlay-open {
    //Variables for overlay
    $dropdown-overlay-close-inset: ($es-space-medium + $es-space-small) $es-space-large;

    @include es-overlay;

    &:before {
        @include es-control-icon(close);
        color: $es-font-color;
        background-color: $es-background-color;
        margin: 0;
        padding: es-to-rem($dropdown-overlay-close-inset);
        position: absolute;
        right: 0;
        top: 0;
        z-index: $es-z-index-topmost;
        cursor: pointer;
    }
    @include es-viewport-range(mobile-large-up) {
        height: auto;
        position: static;
        width: auto;
        z-index: auto;
        &:before {
            display: none;
        }
    }
}

@mixin es-dropdown-list {
    background-color: $es-background-color;
    display: none;
    list-style-type: none;
    overflow-y: auto; 
    li {
        a,
        button,
        & > span {
            background: none;
            border: 0;
            color: $es-font-color;
            display: block;
            min-height: es-to-rem($es-control-height);
            padding: es-to-rem($es-space-inset-squish-default);
            text-align: left;
            width: 100%;
            .es-icon {
                font-size: es-to-rem($es-icon-size-default);
                margin: es-to-rem($es-space-gutter-right-small);
                //harmonize icons and text vertically
                position: relative;
                top: -(es-to-rem(1));
            }
        }
        // this to make the text visually distinct from the clickable items
        // decision made by Kevin on 17-Jul-2019
        & > span {
            font-style: italic;
        }
        a:hover,
        button:focus,
        button:hover {
            background: $es-control-hover-color;
            // color: $es-control-text-hover-color;            
        }
        span.-es-disabled,
        a.-es-disabled,
        button.-es-disabled,
        button:disabled {
            color: $es-disabled-color;            
        }
        a.-es-disabled,
        button.-es-disabled,
        button:disabled {
            background: $es-background-disabled-color;
        }
        span.-es-disabled,
        a.-es-disabled {
            pointer-events: none;
        }
        &.es-divider {
            border-top: 1px solid $es-control-separator-color;
            height: 1px;
            min-height: 1px;
            padding: 0;
        }
    }
}

@mixin es-dropdown-list-tablet {
    background-color: $es-background-color;
    border: $es-control-separator-color;
    box-shadow: 0 6px 12px $es-shadow-color;
    overflow-y: auto;
    li {
        a,
        button {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

%es-dropdown {
    display: inline-block;
    position: relative;
    nav,
    .es-dropdown-overlay {
        @include es-dropdown-overlay;
    }
    ul,
    .es-dropdown-content {
        @include es-dropdown-content;
        @include es-dropdown-list;
    }
    &.-es-open nav,
    &.-es-open .es-dropdown-overlay {
        @include es-dropdown-overlay-open;
    }
    &.-es-open ul,
    &.-es-open .es-dropdown-content {
        @include es-dropdown-content-open;
    }

    ul {
        padding: 0;
    }
    .es-dropdown-content {
        @extend %es-container-inset-modifiers;
    }
    
    @include es-viewport-range(mobile-large-up) {
        position: relative;
        ul,
        .es-dropdown-content {
            @include es-dropdown-list-tablet;
        }
        &.-es-left {
            ul,
            .es-dropdown-content {
                margin-left: 100%;
                transform: translateX(-100%);
            }
        }
        &.-es-right {
            ul,
            .es-dropdown-content {
                left: 100%;
            }
        }
    }
}

@mixin es-dropdown-button {

	&.-es-small {
		&:after {
			// fixed padding for caret icon
			padding: $es-space-inset-squish-small;
		}
		$dropdown-inset-right-small: $es-icon-size + 2*$es-space-small;
		$dropdown-inset-small: $es-space-xsmall $dropdown-inset-right-small $es-space-xsmall $es-space-small;

		padding: es-to-rem($dropdown-inset-small);
		// the height for each control is set to avoid IE issues
		// therefore we also need to change it for the modifier
		height: es-to-rem($es-control-height - 2*$es-space-xsmall);
	}
    // padding is inset squish, but has to be calculated manually
    // because of the caret at the right
    $dropdown-inset-right: $es-icon-size + 2*$es-space-default;
    $dropdown-inset: $es-space-small $dropdown-inset-right $es-space-small $es-space-medium;

    padding: es-to-rem($dropdown-inset);
    width: 100%;
    text-align: left;

    @extend %es-control;
    @extend %es-caret;
}

// @TODO: Needs rework, used by global header only for now
@mixin es-dropdown-list-left-tablet {
    left: auto;
    right: es-em-calc($es-gutter);
}

@mixin es-dropdown-icon {
    // padding is inset squish, but has to be calculated manually
    // because of the caret at the right
    $dropdown-icon-size: 15px;
    $dropdown-chevron-icon-size: 11px;
    $dropdown-chevron-icon-offset: ($dropdown-icon-size - $dropdown-chevron-icon-size) * .5;
    $dropdown-chevron-icon-inset-left: $es-space-xsmall + $dropdown-chevron-icon-offset;
//    $dropdown-chevron-icon-inset: $es-space-xsmall $es-space-xsmall 2*$es-space-xsmall $dropdown-chevron-icon-inset-left;
    $dropdown-chevron-icon-inset: 0 0 0 $dropdown-chevron-icon-inset-left;
    $dropdown-inset-left: $dropdown-icon-size + $es-space-medium; //+ $es-space-xsmall
    $dropdown-inset: 0 0 0 $dropdown-inset-left;
//    $dropdown-inset-left: $dropdown-icon-size + $es-space-small + $es-space-xsmall;
//    $dropdown-inset: $es-space-xsmall $es-space-xsmall $es-space-xsmall $dropdown-inset-left;
    
    $dropdown-label-line-height: 15px;
    $dropdown-text-default-color: $es-control-color;
    
    // use negative margin to move the focus outline out of the box limits
    color: $dropdown-text-default-color;
    display: inline-block;
    padding: es-to-rem($dropdown-inset);
    // use negative margin to move the focus outline out of the box limits
//    margin: es-to-rem($es-space-xsmall) * -1;
    position: relative;
    vertical-align: middle;
    max-width: 100%;

    &:focus,
    &:hover {
      .es-label-stack {
        cursor: pointer;
      }
    }

    .es-icon {
        font-size: es-to-rem($dropdown-icon-size);
        padding: es-to-rem($es-space-inset-xsmall);
        position: absolute;
        left: 0;
        top: 0;
        min-height: es-to-rem($es-control-height);
    }
}

