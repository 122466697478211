//// ----------------------------------------------------------------------
///
/// Popover
///
/// @group containers
/// @module sass/components/containers/popover
///
/// @require function es-color
/// @require function es-to-rem
//// ----------------------------------------------------------------------
$es-popover-background-color: es-color('mono', 'white');

.es-popover {
	.es-popover-header {
		background-color: $es-popover-background-color;
		border-bottom: 1px solid $es-popover-background-color;
		padding: es-to-rem($es-space-inset-medium);
		font-weight: bold;
	}
	.es-popover-body {
		padding: es-to-rem($es-space-inset-medium);
	}
	.es-popover-footer {
		padding: es-to-rem($es-space-inset-small);
		border-top: 1px solid $es-popover-background-color;
	}
	@extend %es-popover;
	// reset button style when a blank button is directly embedded
	// but ignore other buttons that have a class applied
	& > button:not([class]) {
		@extend %es-default-button-reset;
	}
	// disabled state
	& > button:disabled:hover {
		@extend %es-control-disabled;
	}

}
