//// ----------------------------------------------------------------------
///
/// Page components (Header, Footer, Main)
///
/// @group partials
/// @module sass/molecules/page
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

.es-page-header {

  padding: 0 $es-container-border-width 0 $es-container-border-width;

  .es-page-header-title {
    display: inline-block;
    width: 100%;

    & > .es-text-group {
      display: inline-block;
      width: 90%;

      span.sg-type {
      line-height: es-to-rem($es-line-height);
      }
    }

    .es-page-title-icon {

      .es-icon {
        font-size: .75em;
        // vertical-align: sub;
      }
    }

    & > .es-button {
      display: none;
    }

    .es-dropdown {
      padding: es-to-rem($es-space-small + 1px);
    }

    .es-button,
    button,
    .es-dropdown {
      float: right;
    }

    .es-button + .es-button,
    button + button {
      margin: es-to-rem($es-space-gutter-right-default);
    }
  }
}

@include es-viewport-range(mobile-large-up){
  .es-page-header {

    .es-page-header-title {

      & > .es-text-group {
        width: auto;
      }

      & > .es-button {
        display: inline-block;
      }
      & > .es-dropdown {
        display: none;
      }
    }
  }
}