//// ----------------------------------------------------------------------
///
/// mixins and placeholder for common type/element-styles
///
/// @group modules
/// @module sass/modules/core/types
///
//// ----------------------------------------------------------------------

//// ----------------------------------------------------------------------
/// Formats definition list, displays terms abd definitions in-line (next to each other)
///
/// @access public
/// @param em [$dt-width] Width reserved for the term
/// @param em [$gutter] Gutter between term and definition
/// @outputs The calculated attributes

@mixin es-dl-inline($dt-min-width: null, $dt-gutter: null){
  dl {
    margin: 0;
  }
  dt {
    display: inline-block;
    @if ($dt-min-width) {
      min-width: $dt-min-width;
    }
    @if ($dt-gutter) {
      margin: es-to-rem(0 $dt-gutter 0 0);
    }
  }
  dd {
    display: inline;
    margin: 0;
    &:after {
      display: block;
      content: '';
    }
  }
}

//// ----------------------------------------------------------------------
/// Styling for inline-navigation
///
/// @access public
/// @param px [font-size] Font-size
/// @param em [$gutter] Gutter between term and definition
/// @outputs The calculated attributes
@mixin es-contains-inline-nav {
    nav{
      margin: 0 auto;
      text-align: center;
    }
    nav ul, nav li{
      display: inline-block;
    }
    nav ul{
      margin: 0;
      padding: 0;
      li{
        & + li{
          margin: 0 0 0 .75em;
        }
      }
    }
  @include es-viewport-range(mobile-large-up){
    li{
      & + li:before{
        content: "|";
        margin: 0 1em 0 0;
      }
    }
  }
}


//// ----------------------------------------------------------------------
/// Miscellaneous

@mixin es-nav-item() {
  list-style-type: none;

  button, a {
    box-sizing: border-box;
    display: block;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    white-space: nowrap;
    * {
      display: inline-block;
    }
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  button:focus {
    outline: none;
  }

  ul{
    padding: 0;
  }

  .es-icon {
    font-size: es-to-rem($es-nav-item-icon-size);
    & + label {
      margin-left: es-to-rem($es-space-default);
    }
  }
}

@mixin es-nav-item-height($height) {
  min-height: $height;
  button, a {
    height: $height;
    line-height: $height;
  }
}

%es-default-button-reset {
  background: transparent;
  border: none;
  padding: 0;
}

