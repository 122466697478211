//// ----------------------------------------------------------------------
///
/// Modal tour component
///
/// @group container
/// @module sass/components/containers/tour
///
/// @require function es-color
/// @require function es-get-icon
/// @require mixin es-em-calc
/// @require mixin es-overlay
//// ----------------------------------------------------------------------

$es-tour-highlight-color: es-color('clario-mono', 'white', 0.5);
$es-tour-max-width-small: 600px;
$es-tour-max-width-medium: 800px; // also the default
$es-tour-max-width-large: 1000px;

.es-tour-element-highlight {
	position: relative;
	z-index: 102;
}

.es-tour-highlight-frame {
	background: $es-tour-highlight-color;
	display: none;
	position: absolute;
	z-index: 101;
}

.es-tour-overlay {
	@include es-overlay;
	display: none;
	z-index: 100;
}

// tour by default not visible
.es-tour {
	display: none;
}

// open state of tour
.es-tour.-es-open {
	$es-tour-background-color: es-color('clario-mono', 'gray-2');
	// $es-tour-border-color: es-color('mono', 'oslo-grey', .8);
	$es-tour-dot-color-active: es-color('clario-pink', 'bright-pink');
	$es-tour-dot-color-visited: es-color('clario-purple', 'purple-8');
	$es-tour-dot-color: es-color('clario-purple', 'purple-11');
	$es-tour-dot-dimension: es-to-rem(12px);
	$es-tour-footer-background-color: es-color('clario-purple', 'purple-brand');
	$es-tour-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
	$es-tour-text-color: $es-font-color;

	bottom: 0;
	display: block;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: $es-z-index-topmost;

	.es-tour-content {
		background: $es-tour-background-color;
		// border: 1px solid $es-tour-border-color;
		box-shadow: $es-tour-shadow;
		color: $es-tour-text-color;
		left: 0;
		margin: 0;
		position: absolute;
		right: 0;
		z-index: $es-z-index-topmost;
	}

	// modifiers to control position of tour
	&.-es-top {
		.es-tour-content {
			top: 0;
		}
	}
	&.-es-bottom {
		.es-tour-content {
			bottom: 0;
		}
	}

	// modifiers to control width of tour
	&.-es-tour-small {
		.es-tour-content {
			max-width: $es-tour-max-width-small;
		}
	}
	&.-es-tour-medium {
		.es-tour-content {
			max-width: $es-tour-max-width-medium;
		}
	}
	&.-es-tour-large {
		.es-tour-content {
			max-width: $es-tour-max-width-large;
		}
	}

	.es-tour-footer {
		.es-dots {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding: es-to-rem($es-space-small);
			&>li {
				&.-es-active {
					background-color: $es-tour-dot-color-active;
				}
				&.-es-visited {
					background-color: $es-tour-dot-color-visited;
				}
				background-color: $es-tour-dot-color;
				border-radius: 50%;
				height: $es-tour-dot-dimension;
				margin: es-to-rem($es-space-small);
				width: $es-tour-dot-dimension;
			}
		}
		background-color: $es-tour-footer-background-color;
	}

	// Spacing for body
	.es-tour-body {
		@extend %es-container-inset-modifiers;
	}

	// Spacing for footer
	.es-tour-footer {
		@extend %es-container-inset-modifiers;
	}
}

@include es-viewport-range(mobile-large-up) {
	.es-tour.-es-open {
		.es-tour-content {
			margin: es-to-rem($es-space-xlarge) auto;
			width: 80vw;
		}
	}
}
